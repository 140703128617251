@charset "UTF-8";

$prefix-for-webkit:    true    !global;
$prefix-for-mozilla:   true   !global;
$prefix-for-microsoft: true !global;
$prefix-for-opera:     true     !global;
$prefix-for-spec:      true      !global;


@mixin ro-prefixer($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      @if $prefix-for-webkit {
        -webkit-#{$property}: $value;
      }
    } @else if $prefix == moz {
      @if $prefix-for-mozilla {
        -moz-#{$property}: $value;
      }
    } @else if $prefix == ms {
      @if $prefix-for-microsoft {
        -ms-#{$property}: $value;
      }
    } @else if $prefix == o {
      @if $prefix-for-opera {
        -o-#{$property}: $value;
      }
    } @else if $prefix == spec {
      @if $prefix-for-spec {
        #{$property}: $value;
      }
    } @else  {
      @warn "Unrecognized prefix: #{$prefix}";
    }
  }
}

@mixin ro-disable-prefix-for-all() {
  $prefix-for-webkit:    false !global;
  $prefix-for-mozilla:   false !global;
  $prefix-for-microsoft: false !global;
  $prefix-for-opera:     false !global;
  $prefix-for-spec:      false !global;
}
