// pagination
// ============

.pagination {
  margin: 20px 0;
  > span,
  > a {
    @include type(14px);
    padding: 0.3em 0.7em;
  }
  > a {
    &.current,
    &:hover {
      color: #fff;
      background-color: $color-brand;
    }
  }
  > span {
    vertical-align: middle;
    cursor: pointer;
    &:hover {
      color: $color-brand;
    }
  }
}