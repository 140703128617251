// placeholder
//-----------------------------
@import "../addons/opacity";

// clearfix
%ro-hidden-checkbox {
  position: absolute; 
  left: -9999px;
}

// flex-video
%flex-video-container {
  position: relative;
  height: 0;
  overflow: hidden;
}
%flex-video {
  position: absolute;
  width: 100%;
  height: 100%;
}

// tooltip
%tooltip-common {
  @include opacity(0);
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
%tooltip-content {
  padding: 0.67em;
  min-width: 8em;
  font-size: 12px;
  line-height: 1.3;
  font-weight: normal;
  color: #fff;
}

// button
%button-style {
  display: inline-block;
  box-sizing: border-box;
}
