@mixin gallery($key){
  $child: args-get-next($key, 'child', 'li');
  $gutter: args-get-next($key, 'gutter', map-get($ro-layout, gutter));
  $float: if(args-get($key, $ro-text-direction, 'LTR') == 'LTR', left, right);
  $center-align: args-get($key, 'center-align');
  $keep: args-get($key, 'keep');
  @if $float == right { $center-align: false; }

  $global-bps: args-get-global-breakpoints($key);
  @if $global-bps { $key: list-remove($key, $global-bps); } // remove global-breakpoints from args if exist
  $bps: false;
  $condition: args-get($key, ('min' 'max'), 'min');
  $media-type: args-get($key, $ro-media-type);

  // get data
  $data: false;
  @if type-of($key) == 'number' and unitless($key) or 
      type-of($key) == 'map' {
    $data: $key;
  } @else if type-of($key) == 'list' and not $data {
    @each $item in $key {
      @if not $data and
          type-of($item) == 'number' and unitless($item) or
          type-of($item) == 'map' {
        $data: $item;
      }
    }
  }

  // if not valuable data found
  @if not $data { @error 'Parameters must include number or map.'; }
  // strip unit when gutter == 0
  @if not $gutter or type-of($gutter) != 'number' or $gutter and strip-unit($gutter) == 0 { $gutter: 0; }


  @if type-of($data) == 'map' {
    // update $data breakpoints
    @if $global-bps { $data: map-update-breakpoints($data, $global-bps); }
    @if $data and type-of($data) == 'map' { $bps: map-keys($data); }

    @if index($bps, null) != null or index($bps, 'default') != null { 
      $keep: false; 
    }

    @each $bp in $bps {
      $this-per-row: map-get($data, $bp);

      @if $bp == null or $bp == 'default' {
        @include gallery-base($child, $gutter, $float, $center-align);
        $keep: true;

        @include make-gallery($this-per-row, $child, $gutter, $float, $center-align);

      } @else if type-of($bp) == 'number' {
        @include bp($bp $condition) {
          @if not $keep {
            @include gallery-base($child, $gutter, $float, $center-align);
            $keep: true;
          }

          @include make-gallery($this-per-row, $child, $gutter, $float, $center-align);
        }
      }
    }
  } @else {
    @if not $keep {
      @include gallery-base($child, $gutter, $float, $center-align);
    }
    @include make-gallery($data, $child, $gutter, $float, $center-align);
  }
}

@mixin gallery-base($child, $gutter, $float, $center-align){
  @if $center-align { 
    // Webkit: collapse white-space between units
    letter-spacing: -0.31em; 
    // Webkit: fixes text-rendering: optimizeLegibility
    text-rendering: optimizespeed; 
    // Sets the font stack to fonts known to work properly with the above letter
    // and word spacings. See: https://github.com/yahoo/pure/issues/41/
    // The following font stack makes gallery work on all known environments.
    // * FreeSans: Ships with many Linux distros, including Ubuntu
    // * Arimo: Ships with Chrome OS. Arimo has to be defined before Helvetica and
    //   Arial to get picked up by the browser, even though neither is available
    //   in Chrome OS.
    // * Droid Sans: Ships with all versions of Android.
    // * Helvetica, Arial, sans-serif: Common font stack on OS X and Windows.
    font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;

    // Opera as of 12 on Windows needs word-spacing.
    // The ".opera-only" selector is used to prevent actual prefocus styling
    // and is not required in markup.
    .opera-only :-o-prefocus, & { word-spacing: -0.43em; }
    text-align: center;
  } @else {
    @include clearfix(); 
  }

  @if unit($gutter) != '%' and $gutter != 0 { margin-right: - $gutter; }
  
  > #{$child} {
    width: 100%;

    @if $center-align {
      display: inline-block;
      letter-spacing: normal;
      word-spacing: normal;
      vertical-align: top;
      text-rendering: auto;
      text-align: left;
    } @else {
      float: $float;
    }

    @if $gutter != 0 {
      @if unit($gutter) == '%' {
        margin: get-TRBL(#{opposite($float)} $gutter bottom $gutter);
      } @else {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 $gutter $gutter 0;
      }
    }
  }
}

@mixin make-gallery($columns, $child, $gutter, $float, $center-align) {
  > #{$child} { 
    @if unit($gutter) == '%' or $gutter == 0 {
      width: ((100% + $gutter) / $columns - $gutter); 
      
      // reset margin in each breakpoint
      &:nth-child(n) { margin-#{opposite($float)}: $gutter; }
      &:nth-child(#{$columns}n) { margin-#{opposite($float)}: 0; }

    } @else {
      width: percentage(1 / $columns); 
    }
    
    // reset clear in each breakpoint
    @if not $center-align {
      &:nth-child(n) { clear: none; }
      &:nth-child(#{$columns}n+1) { clear: both; }
    }

  }
}