// *** hide-text *** //
@mixin hide-text(){
  text-indent: 110%; 
  white-space: nowrap;
  overflow: hidden; 
}

%hide-text {
  @include hide-text();
}
