// *** rems *** //
// @include rems(margin, 0 10 20 0);
@mixin rems($property, $size, $base: $ro-em-base) {
  @if not unitless($base) {
    $base: strip-unit($base);
  }

  $unitless_values: ();
  @each $num in $size {
    @if not unitless($num) {
      @if unit($num) == "em" {
        $num: $num * $base;
      }

      $num: strip-unit($num);
    }

    $unitless_values: append($unitless_values, $num);
  }
  $size: $unitless_values;

  $rem_values: ();
  @each $value in $size {
    $rem_value: ($value / $base) * 1rem;
    $rem_values: append($rem_values, $rem_value);
  }

  #{$property}: $rem_values;
}

