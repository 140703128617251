// button
// ============

@each $item in 'facebook', 'facebook-like', 'twitter', 'pinterest', 'primary' {
  .button-#{$item} {
    font-size: 14px;
    @include button(3px '0.8em 1.2em' map-get($social, $item) radius hover);
  }
}
.button-primary { border: 1px solid map-get($social, 'primary'); }
.share-line {
  > span,
  > a { margin-bottom: 5px; }
  span, a, iframe { vertical-align: middle; }
  i {
    display: inline-block;
    font-size: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
  }
  .button-twitter { color: #fff; }
  @include bp(max $bp-mobile) {
    .social-text { display: none; }
    i { margin-right: 0; }
  }
}