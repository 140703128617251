@function is-nested-list($list) {
  @if type-of($list) != 'list' {
    @return false;
  }

  $is-nested-list: false;
  @each $item in $list {
    @if not $is-nested-list and 
        type-of($item) == 'list' or 
        type-of($item) == 'map' {
      $is-nested-list: true;
    }
  }

  @return $is-nested-list;
}