// *** angled-edges *** //
@mixin angled-edges ($key) {
  $edges: args-get($key, $ro-angled-edges, 'bottom');
  $flip: args-get($key, 'flip');
  $view: 1800px;

  // set selector
  $selector: '&:before, &:after';
  @if $edges == 'top' {
    $selector: '&:before';
  } @else if $edges == 'bottom' {
    $selector: '&:after';
  }

  // set colors & angles
  $color1: false;
  $color2: false;
  $angle: false;
  $angle2: false;
  @if type-of($key) == 'list' {
    @each $item in $key {
      // colors
      @if type-of($item) == 'color' {
        @if not $color1 {
          $color1: $item;
          $color2: $item;
        } @else {
          $color2: $item;
        }
      }

      // angles
      @if type-of($item) == 'number' and unit($item) == 'deg' {
        @if not $angle {
          $angle: $item;
          $angle2: $item;
        } @else {
          $angle2: $item;
        }
      }
    }
  }
  @if not $color1 {$color1: #fff; }
  @if not $color2 {$color2: #fff; }
  @if not $angle { $angle: 5deg; }
  @if not $angle2 { 
    $angle2: $angle; 
    @if $flip { $angle2: - $angle; }
  }

  // *** output *** //
  position: relative;
  overflow: hidden;
  #{$selector} {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border: 0px solid transparent;
  }

  @if $edges == 'top' or $edges == 'bottom' {
    $dir: right;
    @if $edges == 'bottom' { $dir: left; }

    padding-#{$edges}: edge-tan($angle, 100%);
    #{$selector} {
      #{$edges}: 0;
      border-#{$edges}: edge-tan($angle, $view) solid $color1;

      @if strip-unit($angle) < 0 {
        #{$dir}: 0;
        border-#{$dir}-width: $view;
      } @else {
        #{opposite($dir)}: 0;
        border-#{opposite($dir)}-width: $view;
      }
    }
  } @else if $edges == 'both' {
    padding-top: edge-tan($angle, 100%);
    padding-bottom: edge-tan($angle2, 100%);
    &:before {
      top: 0;
      border-top: edge-tan($angle, $view) solid $color1;

      @if strip-unit($angle) < 0 {
        right: 0;
        border-right-width: $view;
      } @else {
        left: 0;
        border-left-width: $view;
      }
    }
    &:after {
      bottom: 0;
      border-bottom: edge-tan($angle2, $view) solid $color2;

      @if strip-unit($angle2) < 0 {
        left: 0;
        border-left-width: $view;
      } @else {
        right: 0;
        border-right-width: $view;
      }
    }
  }

}

@function edge-tan($angle, $width) {
  @return (tan(abs($angle)) * $width);
}