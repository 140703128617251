@function get-position-from-order($order) {
  $nums: list-increase(list-remove-duplicates($order)); // reordered values
  $temp: ();
  $positions: ();

  @each $num in $nums {
    @for $i from 1 through length($order) {
      @if nth($order, $i) == $num {
        $temp: append($temp, #{$i}); // use number as text
      }
    }
  }

  @each $n in $temp {
    $positions: append($positions, to-number($n)); // transfer text to number
  }  
  @return $positions;
}