// *** push-toggle *** //
@mixin push-toggle() {
  // *** output *** //
  @include clearfix;

  [type="radio"] {
    position: absolute; 
    left: -9999px;
  }
  label {
    float: left;
    display: inline-block;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  input:checked + label {
    @content;
  }
}
