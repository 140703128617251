// *** flex-media *** //
@mixin flex-media($key: ((9/16) false) ){
  $ratio: args-get-type($key, 'number');
  $child: args-get-type($key, 'string');
  @if unitless($ratio) { $ratio: percentage($ratio); }

  // *** output *** //
  @extend %flex-video-container;
  padding-bottom: $ratio;
  @if $child {
    > #{$child} { @extend %flex-video; }
  } @else {
    > iframe,
    > object,
    > embed { @extend %flex-video; }
  }
}