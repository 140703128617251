// *** breakpoint *** //
@mixin ro-breakpoint($key){
  $condition: args-get($key, ('min' 'max'), 'min');
  $media-type: args-get($key, $ro-media-type);
  $media-feature: args-get($key, $ro-media-features, 'width');

  $media-string: if($media-type, '#{$media-type} and ', '');
  $selector: '';
  $bps-em: ();

  // get breakpoints
  @each $item in $key {
    @if type-of($item) == 'number' {
      @if strip-unit($item) == 0 {
        $item: 0em;
      } @else {
        $item: em($item);
      }

      $bps-em: append($bps-em, $item);
    }
  }
  $length: length($bps-em);

  @if $length == 1 {
    $bp: if($breakpoint-fix and $condition == 'max', (nth($bps-em, 1) - em(1)), nth($bps-em, 1));
    $selector: $media-string + '(#{$condition}-#{$media-feature}: #{$bp})';
  } @else if $length >= 2 {
    @for $i from 1 through $length {
      @if number-odd-even($i) == 'odd' {
        $selector: $selector + ', ' + $media-string + ' (min-#{$media-feature}: #{nth($bps-em, $i)})';
      } @else if number-odd-even($i) == 'even' {
        $bp-max: if($breakpoint-fix, (nth($bps-em, $i) - em(1)), nth($bps-em, $i));
        $selector: $selector + 'and (max-#{$media-feature}: #{$bp-max})';
      }
    }
    // remove the first ', '
    @if str-index($selector, ', ') == 1 { $selector: str-slice($selector, 3); }
  } @else if $length == 0 {
    @error "No breakpoint found.";
  }

  // *** output *** //
  @media #{unquote($selector)} { @content; }
}

@mixin bp($key) {
  @include ro-breakpoint($key) {
    @content;
  };
}