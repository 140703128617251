// section
// =========

.page-wrap-section { 
  @include liquid-2($layout-section); 
}
.news-list-section {
  margin-bottom: 10px;
  li {
    padding: em(10) 0;
    border-bottom: 1px solid $color-border;
    &:first-child { padding-top: 0; }
    &:last-child { border-bottom-width: 0; }
  }
  .sponsored {
    margin-top: -1px;
    border-top: 1px solid #d5d5d5;
    border-bottom-width: 0;
    background-color: #f5f5f5;
  }
  @include bp('min' 460px) {
    figure { @include media-list('media' em(15)); }
    .media-body { @include media-list('media-body'); }
  }
}
.social {
  > div,
  > iframe {
    display: inline-block !important;
    vertical-align: top !important;
    margin-bottom: 5px;
  }
}