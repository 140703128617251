@mixin liquid-2($key){
  $columns: false;
  $order: false; 
  $child: args-get-next($key, 'child', 'div');
  $gutter: args-get-next($key, 'gutter', map-get($ro-layout, 'gutter'));
  $float: if(args-get($key, $ro-text-direction, 'LTR') == 'LTR', left, right);

  $global-bps: args-get-global-breakpoints($key);
  @if $global-bps { $key: list-remove($key, $global-bps); } // remove global-breakpoints from args if exist
  $bps: false;
  $condition: args-get($key, ('min' 'max'), 'min');
  $media-type: args-get($key, $ro-media-type);

  // strip unit when gutter == 0
  @if not $gutter or type-of($gutter) != 'number' or $gutter and strip-unit($gutter) == 0 { $gutter: 0; }

  // get data
  $data: args-get-liquid-data($key, 2);

  @include clearfix(); 
  
  // using map
  @if type-of($data) == 'map' {

    // simple map, without media querys
    // ('auto' 200px : 2 1)
    @if length(nth(map-keys($data), 1)) == 2 {
      $columns: nth(map-keys($data), 1);
      $order: nth(map-values($data), 1);
      @include make-liquid-2($columns, $order, $child, $gutter, $float);

    // ('auto' : 2, 200px : 1)
    } @else if is-simple-map($data) {
      $columns: map-keys($data);
      $order: map-values($data);
      @include make-liquid-2($columns, $order, $child, $gutter, $float);

    // complex map, with media querys
    } @else {
      @if $global-bps { $data: map-update-breakpoints($data, $global-bps); }
      $bps: map-keys($data);

      @each $bp in $bps {
        $layout: map-get($data, $bp);

        @if type-of($layout) == 'list' {
          $columns: $layout;
          @if not $order { $order: (1, 2); }
        } @else if type-of($layout) == 'map' {
          $columns: map-keys($layout);
          $order: map-values(map-get($data, $bp));
        }

        @if $bp == 'default' or $bp == null {
          @include make-liquid-2($columns, $order, $child, $gutter, $float);
        } @else {
          @include bp($condition $media-type $bp) {
            @include make-liquid-2($columns, $order, $child, $gutter, $float);
          }
        }
      }
    }
    
  // using list
  } @else {
    $columns: $data;
    $order: (1, 2);

    @include make-liquid-2($columns, $order, $child, $gutter, $float);
  }

}

@mixin make-liquid-2($columns, $order, $child, $gutter, $float) {
  $column-unit: if(nth($columns, 1) == null or nth($columns, 1) == 'auto',
    unit(nth($columns, 2)),
    unit(nth($columns, 1))
  );
  $gutter-calc: if($gutter == 0, 0px, $gutter);
  $gutter-ie8: gutter-fallback($gutter, $column-unit);

  $child-list: ();
  @if type-of($child) == 'string' {
    $child-list: ('#{$child}:nth-child(1)', '#{$child}:nth-child(2)');
  } @else if type-of($child) == 'list' and length($child) == 2 {
    $child-list: $child;
  } 

  $positions: get-position-from-order($order);
  $new-columns: ();
  @for $i from 1 through 2 {
   $new-columns: append($new-columns, nth($columns, nth($positions, $i)));
  } 

  // flexible-column is on the left
  @if nth($new-columns, 1) == null or nth($new-columns, 1) == 'auto' {

    .lt-ie9 & {
      padding: get-TRBL(opposite($float) (nth($new-columns, 2) + $gutter-ie8));
      // padding-right: (nth($new-columns, 2) + $gutter);
      box-sizing: border-box;
    }

    @for $i from 1 through length($new-columns) {

      > #{nth($child-list, nth($positions, $i))} {
        @if $i == 1 {
          float: $float;
          // float: left;
          width: 100%;
          width: -webkit-calc(100% - #{nth($new-columns, 2)} - #{$gutter-calc});
          width: -moz-calc(100% - #{nth($new-columns, 2)} - #{$gutter-calc});
          width: calc(100% - #{nth($new-columns, 2)} - #{$gutter-calc});
        } @else {
          float: opposite($float);
          width: nth($new-columns, 2);
          // float: right;

          .lt-ie9 & {
            margin: get-TRBL(opposite($float) (- (nth($new-columns, 2) + $gutter-ie8)));
            // margin-right: (- (nth($new-columns, 2) + $gutter));
          }
        }
      }
    }

  // flexible-column is on the right
  } @else {

    .lt-ie9 & {
      padding: get-TRBL($float (nth($new-columns, 1) + $gutter-ie8));
      // padding-left: (nth($new-columns, 1) + $gutter);
      box-sizing: border-box;
    }

    @for $i from 1 through length($new-columns) {

      > #{nth($child-list, nth($positions, $i))} {
        @if $i == 1 {
          float: $float;
          width: nth($new-columns, 1);
          // float: left;

          .lt-ie9 & {
            margin: get-TRBL($float (- (nth($new-columns, 1) + $gutter-ie8)));
            // margin-left: (- (nth($new-columns, 1) + $gutter));
          }

        } @else {
          float: opposite($float);
          // float: right;
          width: 100%;
          width: -webkit-calc(100% - #{nth($new-columns, 1)} - #{$gutter-calc});
          width: -moz-calc(100% - #{nth($new-columns, 1)} - #{$gutter-calc});
          width: calc(100% - #{nth($new-columns, 1)} - #{$gutter-calc});
        }
      }
    }
  }

}