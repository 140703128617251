/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
/// http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement

@mixin hide-text($height: null) {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;

  @if $height {
    @warn "The `hide-text` mixin has changed and no longer requires a height. The height argument will no longer be accepted in v5.0.0";
  }
}
