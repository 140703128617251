// *** checkbox *** //
@mixin checkbox(){
  > input { 
    position: absolute; 
    left: -9999px;
  }
  > label {
    display: inline-block;
    @content;
  }
}

@mixin checkbox-active(){
  > input:checked + label {
    @content;
  }
}