// browsehappy
// =============

.enable-js,
.browsehappy {
  background: #FFBF29;
  color: #000;
  font-size: 14px; 
  margin: 0;
  padding: 0.5em;
  text-align: center;
  line-height: 1.3;
  position: relative;
  z-index: 99999;
  a { 
    font-weight: bold; 
    color: #000;
    &:hover {
      color: #fff;
    }
  }
}