// footer
// ==========

.site-footer {
  @include type($franklin-boo);
  margin-top: 2.4em;
  text-transform: uppercase;
}
.footer {
  &-wrap {
    padding: 10px 0;
    border-top: 1px solid $color-border;

    // one more lines
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -3px;
      width: 100%;
      border-top: 1px solid $color-border;
    }
  }
  &-logo {
    @include rp-type($type-logo-footer);
    a { display: block; }
  }
  &-social {
    a {
      display: inline-block;
      padding: 0 0.3em;
    }
  }
  &-links {
    padding: 10px 0;
    border-top: 1px solid $color-border;
    @include type(14px);
    li {
      display: inline-block;
      margin-right: 1.5em;
    }
    a:hover { color: $color-brand; }
  }
}
.copyright {
  @include type(13px);
  color: #999;
}
@include bp(min $bp-mobile) {
  .footer-logo, .footer-social { line-height: 30px; }
  .footer-logo, .copyright { float: left; }
  .footer-social { float: right; }
  .copyright { margin: 11px 0 0 10px;}
}
@include bp(max $bp-mobile) {
  .footer-social { margin-top: 5px; }
}