@charset "UTF-8";

@font-face {
  font-family: "ecu";
  src:url("fonts/ecu.eot");
  src:url("fonts/ecu.eot?#iefix") format("embedded-opentype"),
    url("fonts/ecu.woff") format("woff"),
    url("fonts/ecu.ttf") format("truetype"),
    url("fonts/ecu.svg#ecu") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "ecu" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ecu-"]:before,
[class*=" ecu-"]:before {
  font-family: "ecu" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ecu-search:before {
  content: "\61";
}
.ecu-twitter-alt:before {
  content: "\63";
}
.ecu-facebook:before {
  content: "\64";
}
.ecu-rss:before {
  content: "\65";
}
.ecu-angle-down:before {
  content: "\66";
}
.ecu-angle-left:before {
  content: "\67";
}
.ecu-angle-right:before {
  content: "\68";
}
.ecu-angle-up:before {
  content: "\69";
}
.ecu-angle-up-b:before {
  content: "\6a";
}
.ecu-angle-right-b:before {
  content: "\6b";
}
.ecu-angle-left-b:before {
  content: "\6c";
}
.ecu-angle-down-b:before {
  content: "\6d";
}
.ecu-close:before {
  content: "\6e";
}
.ecu-google-plus:before {
  content: "\62";
}
.ecu-twitter:before {
  content: "\6f";
}
.ecu-pinterest:before {
  content: "\70";
}
.ecu-bars:before {
  content: "\72";
}
