// variables
//-----------------------------

// layout
$ro-layout: (
  'container': 1024px,
  'gutter': 20px,
) !default;

// setting
$breakpoint-fix: true !default;

// base
$ro-em-base: 16px !default;
$ro-rem-base: 16px !default;

// transition
$ro-global-animation-duration: 0.3s !default;
$ro-global-bezier: cubic-bezier(0.645, 0.045, 0.355, 1) !default;

$ro-vertical-align: baseline, sub, super, text-top, text-bottom, middle, top, bottom;
$ro-direction: left, right, top, bottom !default;
$ro-text-direction: 'LTR', 'RTL' !default;

// breakpoint
$ro-media-type: all, aural, braille, handheld, print, projection, screen, tty, tv, embossed !default;
$ro-media-features: 'width', 'height' !default;

// type
$ro-font-weights: thin, hairline, extra light, ultra light, lighter, light, normal, medium, semi bold, demi bold, bold, bolder, extra bold, black, heavy, 100, 200, 300, 400, 500, 600, 700, 800, 900, weight-normal, weight-inherit !default;
$ro-font-styles: italic, oblique, style-normal, style-inherit !default;
$ro-text-aligns: left, right, center, justify, align-inherit !default;
$ro-text-transforms: capitalize, uppercase, lowercase, none, full-width, transform-inherit !default;

// media list
$ro-media-list-type: 'media', 'media-body';
$ro-media-direction: left, right;

// off-canvas
$ro-off-canvas-direction: left, right, top, bottom !default;
$ro-off-canvas-modes: 'slide-in', 'slide-along', 'slide-out', 'rotate-in', 'rotate-out', 'rotate-in-reverse', 'scale-down', 'scale-up', 'open-door', 'push', 'reveal', 'drawer' !default;
$ro-off-canvas-z-index: (
  'page': 201,
  'nav': 200,
  'page-overlay': 199,
) !default;

// button
$ro-button-style: 'highlight', 'simple', 'slide', 'ripple', 'veil', 'push', 'cut', 'bubble', 'line-drawing', 'shake' !default;
$ro-button-direction: left, right, top, bottom, 'horizontal', 'vertical' !default;
$ro-button-round: 1000px !default;
$ro-lightness: 10% !default; 
$ro-timing-function: linear, ease, ease-in, ease-out, ease-in-out !default;
$ro-unit: 'em', 'ex', '%', 'px', 'cm', 'mm', 'in', 'pt', 'pc', 'ch', 'rem', 'vh', 'vw', 'vmin', 'vmax' !default; 

// tooltip
$ro-tooltip-position: 'left', 'right', 'top', 'bottom' !default;
$ro-tooltip-radius: 0.22em !default;

// dropdown
$ro-dropdown-show: 'hover', 'click' !default;
$ro-dropdown-style: 'display', 'scale', 'rotate' !default;
$ro-dropdown-direction: left, right !default;

// tabs
$ro-tabs-style: 'normal', 'carousel', 'customize' !default;

// color functions
$ro-loops : 0;
$ro-color-methods: 'adjacent', 'complementary', 'split-complementary', 'triad', 'rectangle', 'square' !default;
$ro-color-dist: 30 !default;
// get data from http://paletton.com/
$ro-normal-model: 0, 7, 12, 17, 21, 24, 27, 30, 32, 34, 36, 38, 40, 41, 44, 45, 47, 48, 50, 52, 53, 55, 56, 58, 60, 64, 67, 70, 73, 77, 80, 84, 87, 93, 99, 107, 120, 136, 147, 154, 162, 170, 180, 191, 200, 206, 212, 216, 222, 227, 233, 240, 245, 250, 254, 258, 262, 266, 270, 274, 278, 284, 291, 300, 312, 319, 326, 332, 337, 342, 347, 353, 360;

$ro-ideal-model: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185, 190, 195, 200, 205, 210, 215, 220, 225, 230, 235, 240, 245, 250, 255, 260, 265, 270, 275, 280, 285, 290, 295, 300, 305, 310, 315, 320, 325, 330, 335, 340, 345, 350, 355, 360;

// validation
$validation-style: 'normal', 'fade-in', 'slide-in' !default;
$validation-direction: left, right, top, bottom !default;
$validation-background: (
  'valid': #40B328,
  'required': #272106,
  'error': #FC2625,
);

// charts
$ro-charts-type: 'bar', 'column', 'pie' !default;

// slider
$ro-slider-carousel-style: 'scale', 'rotate' !default;
$ro-slider-gallery-style: 'scale-in', 'scale-out', 'rotate-in', 'rotate-out', 'fall-down' !default;

// switch
$ro-switch-style: 'toggle', 'slider' !default;

// angled-edges
$ro-angled-edges: 'top', 'bottom', 'both' !default;

// drop-shadow
$ro-drop-shadow-styles: 'lifted', 'raised', 'perspective', 'curve' !default;
$ro-drop-shadow-directions: left, right, top, bottom, 'horizontal', 'vertical' !default;

// masonry cluster
$ro-masonry-cluster-directions: row, row-reverse, column, column-reverse !default;

// diamond
$ro-diamond-shape: 'diamond', 'octagon' !default;

// responsive-table
$responsive-table-mode: 'flip-scroll' 'scroll' 'stack' 'toggle' !default;
