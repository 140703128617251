@function list-remove-duplicates($list) {
  @if type-of($list) != 'list' {
    @warn '"#{$list}" is not a list.'
  }

  $result: ();

  @each $item in $list {
    @if not index($result, $item) {
      $result: append($result, $item);
    }
  }

  @return $result;
}
