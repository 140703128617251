// home
// ===========

.page-wrap-home { 
  @include grid(grid $layout-home); 
}
.gallery-featured {
  @include gallery(2); 
  li { @include type(15px 1.2); }
}
.topnews {
  li { @extend %clearfix; }
  > ul > li {
    border-bottom: 1px solid $color-border;
    padding-bottom: 10px;
    &:last-child { border-bottom-width: 0; }
  }
  .story-heading {
    @include type(17px 1.2);
    margin: .8em 0 .2em;
  }
  .primary {
    aside { margin-bottom: 1em; }
    li {
      @extend .summary;
      margin-bottom: .3em;
      &:before {
        content: '●';
        color: #C4302E;
        font-size: 8px;
        margin-right: 4px;
        position: relative;
        top: -.3em;
      }
    }
    a { color: #444; }
  }
}
.leading {
  .story-heading { margin: .8em 0; }
  li {
    @extend %clearfix;
    padding-bottom: 1em;
    border-top: 1px solid $color-border;
    @include bp(min 500) {
      &:first-child .story-heading {
        font-size: 1.6em;
      }
    }
    @include bp(min $bp-mobile) {
      &:first-child { 
        border-top-width: 0; 
        .story-heading { margin-top: 0; }
      }
    }
  }
}
.featured, .morenews {
  border-top: 1px solid $color-border;
}
.featured-top {
  @extend %clearfix;
  position: relative;
  margin-bottom: $gutter;
  @include bp('min' 640) {
    margin-bottom: $gutter;
  }
  @include bp('min' 1024) {
    margin-bottom: $gutter;
  }
  img { float: left; }
  .title {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 19px;
    padding: 0.7em 0.5em;
  }
}
.leading {
  .story-heading {
    @include type(1.1 italic weight-normal $georgia)
  }
}
.morenews {
  li {
    @include type(17px 1.2 bold);
    padding: 1.2em 0;
    border-bottom: 1px dashed $color-border;
    &:first-child { padding-top: 0.3em; }
    &:last-child { border-bottom-width: 0; }
  }
}
.region {
  .heading-column { border-top: 1px solid $color-border; }
  .story-heading {
    font-size: 14px;
    margin-bottom: .7em;
  }
  li li {
    @include type(14px 1.3);
    margin: .7em 0;
    &:first-child { 
      margin-top: 0;
      border-bottom: 1px dashed $color-border; 
    }
  }
}