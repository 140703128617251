// ======= ad ======= //
.ad {
  margin-bottom: em(20);
  @include center();
  // &250 {width: 250px; }
  // &300 {width: 300px; }
  // &320 {width: 320px; }
  // &400 {width: 400px; }
  // &468 {width: 468px; }
  // &728 {width: 728px; }
  // &970 {width: 970px; }
  &-bg { 
    @extend %clearfix;
    padding-top: 1px;
    background-color: #eee;
  }
  &-group {
    text-align: center;
    > * {
      display: inline-block;
      margin-left: 3px;
      margin-right: 3px;
    }
  }
  &-combined { 
    @extend %clearfix;
    background-color: #f2f2f2; 
    margin-bottom: em(20);
  }
}