// navigation
// ============

.menu {
  .current { color: $color-brand; }
  li { @include rp-type($type-menu); }
  a { display: block; }
}
@include bp('min' $bp-menu) {
  .menu {
    margin-left: auto;
    background: #fff;
    z-index: $zi-menu;
    ul {
      width: 100%;
      display: table;
      padding: 5px 0;
      border-top: 1px solid $color-border;
      border-bottom: 3px solid #000;
    }
    li {
      display: table-cell;
      border-right: 1px solid $color-border;
      padding: 0 1.2em;
      line-height: 20px;
      text-align: center;
      transition: font-size 0.3s;
      &:last-child { border-right-width: 0; }
      &:hover a { color: $color-brand; }
    }
  }
}
@include bp('max' $bp-menu) {
  .page { @include off-canvas('slide-in' '.menu' left 200px); }
  .menu {
    background: #222;
    li { color: #fff; }
    a { padding: 0.5em 1em; }
  }
  #sidebar { margin-left: 0; } // reset for amp
}
