// *** diamond *** //
@mixin diamond($key){
  $shape: args-get($key, $ro-diamond-shape, 'diamond');
  $tight: args-get($key, 'tight');
  $keep: args-get($key, 'keep');

  $global-bps: args-get-global-breakpoints($key);
  @if $global-bps { $key: list-remove($key, $global-bps); } // remove global-breakpoints from args if exist
  $bps: false;
  $condition: args-get($key, ('min' 'max'), 'min');
  $media-type: args-get($key, $ro-media-type);

  // get data
  $data: false;
  @if type-of($key) == 'number' or type-of($key) == 'map' {
    $data: $key;
  } @else if type-of($key) == 'list' and not $data {
    @each $item in $key {
      @if not $data and type-of($item) == 'number' or type-of($item) == 'map' {
        $data: $item;
      }
    }
  }

  // if not valuable data found
  @if not $data { @error 'Parameters must include number or map.'; }
  
  // *** output *** //
  @if not $keep {
    
  }

  // set col width
  @if type-of($data) == 'map' {
    // update $data breakpoints
    @if $global-bps { $data: map-update-breakpoints($data, $global-bps); }
    @if $data and type-of($data) == 'map' { $bps: map-keys($data); }

    @if index($bps, null) != null or index($bps, 'default') != null { 
      $keep: false; 
    } 

    @each $bp in $bps {
      $num: map-get($data, $bp);

      @if $bp == null or $bp == 'default' {
        @include diamond-base($shape);
        $keep: true;

        @include make-diamond($num, $shape, $tight);

      } @else if type-of($bp) == 'number' {
        @include bp($bp $condition) {
          @if not $keep {
            @include diamond-base($shape);
            $keep: true;
          }

          @include make-diamond($num, $shape, $tight);
        }
      }
    }
  } @else {
    @include diamond-base($shape);
    @include make-diamond($data, $shape, $tight);
  }
}

@mixin diamond-base($shape){
  position: relative;
  float: left;
  overflow: hidden;

  @if $shape == 'diamond' { 
    @include ro-transform( rotate(-45deg) scale((1/sqrt(2))) ); 
  } @else if $shape == 'octagon' {
    @include ro-transform(rotate(-45deg));
  }
  > .diamond-content {
    @include center();
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    @if $shape == 'diamond' {
      @include ro-transform( rotate(45deg) scale( sqrt(2) ) );
    } @else if $shape == 'octagon' {
      @include ro-transform(rotate(45deg));
      overflow: inherit;
    }
  }
}

@mixin make-diamond($data, $shape, $tight) {
  $count: false;
  $size: false;

  // count & size
  @if type-of($data) == 'number' {
    @if unitless($data) {
      $count: $data; 
    } @else {
      $size: $data;
    }
  } @else if type-of($data) == 'list' {
    @each $item in $data {
      @if type-of($item) == 'number' {
        @if unitless($item) {
          $count: $item;
        } @else {
          $size: $item;
        }
      }
    }
  }
  @if $size and unit($size) == '%' and $count {
    $count: floor(100% / $size);
  }
  @if not $size {
    @if $count {
      $size: percentage(1 / $count);
    }
  } 
  @if not $count {
    @if $size and unit($size) == '%' {
      $count: floor(100% / $size);
    }
  } 

  @if $size {
    width: $size;
    @if unit($size) == '%' {
      height: 0;
      padding-bottom: $size;
    } @else {
      height: $size;
    }

    @if $shape == 'diamond' {
      @if $count and $tight {
        &:nth-child(n) {
          margin: 0;
          clear: none;
        }
        &:nth-child(#{$count * 2 - 1}n + #{($count + 1)}) {
          margin-left: ($size / 2);
          clear: both;
        }
        &:nth-child(n + #{($count + 1)}) {
          margin-top: (- $size / 2);
        }
        .lt-ie9 &:nth-child(n) { 
          margin: 0; 
          clear: none;
        }
      }
    } 
  }

}