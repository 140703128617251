// *** input-file *** //
@mixin input-file(){
  > input {
    .js & {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    + label {
        display: inline-block;
        cursor: pointer;
        @content;
        .no-js & { display: none; }
    }
    &:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
      * { pointer-events: none; }
    }
  }

}
