// *** color core *** //
// color
@function color($key){
  $method: args-get($key, $ro-color-methods);
  $color: args-get-type($key, 'color');
  $saturation: args-get-next($key, 'saturation', 0%);
  $lightness: args-get-next($key, 'lightness', 0%);
  $dist: args-get-next($key, 'dist', $ro-color-dist);

  $key: list-remove($key, $saturation);
  $key: list-remove($key, $lightness);
  $order: args-get-type($key, 'number', false, 0);

  $hue: hue($color);
  $target-hue: 0deg;
  
  @if $method == adjacent {
    $target-hue: to-normal-hue(to-ideal-hue($hue) + $dist * $order);
  }
  @if $method == complementary {
    $target-hue: to-normal-hue(to-ideal-hue($hue) + 180);
  }
  @if $method == split-complementary {
    @if $order == 1 or $order == -2 {
      $target-hue: to-normal-hue(to-ideal-hue($hue) + 180 - $dist);
    }
    @if $order == 2 or $order == -1 {
      $target-hue: to-normal-hue(to-ideal-hue($hue) + 180 + $dist);
    }
  }
  @if $method == triad {
    @if $order == 1 or $order == -2 {
      $target-hue: to-normal-hue(to-ideal-hue($hue) + 120);
    }
    @if $order == 2 or $order == -1 {
      $target-hue: to-normal-hue(to-ideal-hue($hue) + 240);
    }
  }
  @if $method == rectangle {
    @if abs($order) == 1 {
      $target-hue: to-normal-hue(to-ideal-hue($hue) + $dist * $order);
    }
    @if $order == 2 {
      $target-hue: to-normal-hue(to-ideal-hue($hue) + 180);
    }
    @if abs($order) == 3 {
      $target-hue: to-normal-hue(to-ideal-hue($hue) + 180 + $dist * $order / 3);
    }
  }
  @if $method == square {
    @if $order == 1 or $order == -3 {
      $target-hue: to-normal-hue(to-ideal-hue($hue) + 90);
    }
    @if $order == 2 {
      $target-hue: to-normal-hue(to-ideal-hue($hue) + 180);
    }
    @if $order == 3 or $order == -1 {
      $target-hue: to-normal-hue(to-ideal-hue($hue) + 270);
    }
  }

  // return value
  @return adjust-color($color, $hue: ( $target-hue - $hue ), $saturation: $saturation, $lightness: $lightness);
}


// to-ideal-hue
@function to-ideal-hue($hue){
  $ideal-hue: 0;
  $gap: 0;
  @while $hue >= 360 { $hue: ($hue - 360); }
  @while $hue < 0 { $hue: ($hue + 360); }
  
  @if $hue == 0deg {
    $ideal-hue: 0;
  }
  @else {
    @for $i from 1 through length($ro-normal-model) {
      @if $hue >= nth($ro-normal-model, $i) and
        $hue < nth($ro-normal-model, $i + 1) {
        $gap: (nth($ro-ideal-model, $i + 1) - nth($ro-ideal-model, $i)) * ($hue - nth($ro-normal-model, $i)) / (nth($ro-normal-model, $i + 1) - nth($ro-normal-model, $i));
        $ideal-hue: nth($ro-ideal-model, $i) + $gap;
      }
    }
  }
  @return $ideal-hue;
}

// to-normal-hue
@function to-normal-hue($hue){
  $normal-hue: 0;
  $gap: 0;
  @while $hue >= 360 { $hue: $hue - 360; }
  @while $hue < 0 { $hue: ($hue + 360); }

  @if $hue == 0deg {
    $ideal-hue: 0;
  }
  @else {
    @for $i from 1 through length($ro-ideal-model) {
      @if $hue >= nth($ro-ideal-model, $i) and
        $hue < nth($ro-ideal-model, $i + 1) {
        $gap: (nth($ro-normal-model, $i + 1) - nth($ro-normal-model, $i)) * ($hue - nth($ro-ideal-model, $i)) / (nth($ro-ideal-model, $i + 1) - nth($ro-ideal-model, $i));
        $normal-hue: nth($ro-normal-model, $i) + $gap;
      }
    }
  }
  @return $normal-hue;
}
