// article
// =========
@function powers($num, $power) {
  $return: 1;
  @if $power > 0 {
    @for $i from 1 through $power {
      $return: $return * $num;
    }
  }
  @return $return;
}

.by-line {
  font-size: 12px;
  margin: 10px 0;
  color: #999;
  .author {
    font-weight: bold;
    margin-right: 0.4em;
    &:hover { color: $color-brand;}
  }
  time {
    display: inline-block;
    margin-left: 0.4em;
  }
}
.related-news,
.relatedArticle {
  display: none;
  @include bp('min' 600) {
    display: block;
    float: left;
    width: 250px;
    margin-bottom: 20px;
    margin-right: 20px;
    li { font-weight: bold; }
  }
}
.relatedArticle {
  .label {
    @extend .heading-column;
  }
}

$font-scale: 1.15;
$font-base: 1em;
$p-line-height: 1.5;
.article {
  header {
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
  }
  .figure-main {
    max-width: 600px;
    margin: 20px auto;
  }
  figcaption {
    @include type(12px 1.3);
    margin: 3px 0;
    color: #444;
    .credit { color: #999; }
  }
  h1, h2, h3, h4, h5, h6 { margin-bottom: 0.8em; line-height: 1.1; }
  h1 { font-size: $font-base * powers($font-scale, 5); }
  h2 { font-size: $font-base * powers($font-scale, 4); }
  h3 { font-size: $font-base * powers($font-scale, 3); }
  h4 { font-size: $font-base * powers($font-scale, 2); }
  h5 { font-size: $font-base * powers($font-scale, 1); }
  h6 { font-size: $font-base; }
  section > ul, section > ol,
  blockquote, p {
    @include type(16px $p-line-height lighter);
    a {
      color: $color-brand;
      &:hover { color: #aaa; }
    }
  }
  section > ul, section > ol,
  blockquote ul,
  blockquote ol { margin: $p-line-height * 1em 0 $p-line-height * 1em 2em; }
  section > ul, blockquote ul { list-style-type: disc; }
  section > ol, blockquote ol { list-style-type: decimal; }
  li { 
    margin-bottom: 0.5em;
    a:hover { color: #000; }
  }
  blockquote {
    margin: 0 0 $p-line-height * 1em 0;
    padding-left: $p-line-height * 1em;
    border-left: 0.45em solid $color-border;
    font-style: italic;
    color: #715e5e;
    p { color: inherit; }
  }
  p { margin: 1.5em 0; }
}
.article-copyright {
  font-size: 12px;
  color: #aaa;
  margin-bottom: 20px;
}
.twin-column {
  @include bp(min $bp-mobile) { @include gallery(2); }
}