// subscribe-box
// ============

.subscribe-box {
  background: #f6f6f6;
  padding: 20px;
  box-sizing: border-box;
  max-width: 400px;
  margin: 0 auto 20px;
  h3 { padding-top: 0; }
  form {
    position: relative;
  }
  input {
    width: 100%;
    border-width: 0;
  }
  button {
    position: absolute;
    right: 0;
    top: 0;
    width: 15%;
    max-width: 5em;
    cursor: pointer;
  }
}