@mixin grid($key){
  $child: args-get-next($key, 'child', 'div');
  $gutter: args-get-next($key, 'gutter', map-get($ro-layout, 'gutter'));
  $float: if(args-get($key, $ro-text-direction, 'LTR') == 'LTR', left, right);
  $keep: args-get($key, 'keep', false);

  $global-bps: args-get-global-breakpoints($key);
  @if $global-bps { $key: list-remove($key, $global-bps); } // remove global-breakpoints from args if exist
  $bps: false;
  $condition: args-get($key, ('min' 'max'), 'min');
  $media-type: args-get($key, $ro-media-type);

  @if type-of($child) == 'list' {
    @error 'Only one type of child element is acceptable. You passed (#{$child}).';
  } @else if type-of($child) != 'string' {
    @error 'Child should be a string. You passed "#{$child}".';
  }

  // strip unit when gutter == 0
  @if not $gutter or type-of($gutter) != 'number' or $gutter and strip-unit($gutter) == 0 { $gutter: 0; }

  // get data
  $data: false;
  @if type-of($key) == map {
    $data: $key;
  } @else if type-of($key) == 'list' {
    @if is-number-map-list($key) {
      $data: $key;
    } @else {
      @each $item in $key {
        @if not $data {
          @if type-of($item) == 'map' or 
              type-of($item) == 'list' and is-number-map-list($item) {
            $data: $item;
          }
        }
      }
    }
  }

  // if not valuable data found
  @if not $data { @error 'Parameters must include list or map.'; }

  // 1. Modern browser:
  //    gutter: flex/space-between
  //    order: flex/order
  //    direction: flex/row-reverse

  //   1-1. percentage gutter
  //        width: %

  //   1-2. gutter with other units
  //        width: calc()

  // 2. IE9-:
  //    width: %
  //    direction: float
  //    order: margin/float, -100% margin/opposite-float

  //   2-1. percentage gutter
  //        gutter: no set

  //   2-2. gutter with other units
  //        gutter: minus margin of the container element, padding of child elements

  // has breakpoints
  @if grid-has-breakpoints($data) {
    
    // update breakpoints
    @if $global-bps { $data: map-update-breakpoints($data, $global-bps); }
    $bps: map-keys($data);

    @if index($bps, null) != null or index($bps, 'default') != null { 
      $keep: false; 
    }

    // divide rows within each breakpoints
    @each $bp in $bps {
      @if $bp == 'default' {
        @include grid-base($child, $float, $gutter);
        $keep: true;

        @include divide-rows(map-get($data, $bp), $gutter, $child, $float);
      } @else {
        @include bp($condition $media-type $bp) {
          @if not $keep {
            @include grid-base($child, $float, $gutter);
            $keep: true;
          }

          // reset order
          // * only needed within media query condition
          > #{$child}:nth-child(n) { @include ro-order(0); }
          @include divide-rows(map-get($data, $bp), $gutter, $child, $float);
        }
      }
    }

  // no breakpoints
  } @else {
    @include grid-base($child, $float, $gutter);
    @include divide-rows($data, $gutter, $child, $float);
  }
}

@mixin grid-base($child, $float, $gutter){
  // 1. Modern browser:
  // flex/space-between
  @include ro-flexbox; 
  @include ro-flex-wrap(wrap);
  @include ro-justify-content(space-between);
  // change direction
  @if $float == right {
    @include ro-flex-direction(row-reverse);
  }

  // default 100% width
  > #{$child} { width: 100%; }

  // 2. IE9-:
  .lt-ie10 & {
    @include clearfix();
    // gutter: px | em | rem | vw
    @if $gutter != 0 and unit($gutter) != '%' { margin-#{opposite($float)}: - $gutter; }

    > #{$child} {
      float: $float;
      clear: both; // default: single column
      margin-#{opposite($float)}: -100%; // each column is isolated with each other
      // gutter: px | em | rem | vw
      @if $gutter != 0 and unit($gutter) != '%' {
        padding-#{opposite($float)}: $gutter;
        box-sizing: border-box;
      }
    }
  }
}

// == divide-rows ==
@mixin divide-rows($value, $gutter, $child, $float) {
  $columns: false;
  $orders: false;
  $list-length: 0;
  $max-order: false;
  
  // reset clear
  .lt-ie10 & > #{$child}:nth-child(n) { clear: none; };

  // many rows
  @if is-nested-list($value) {

    // make grid in each row
    @each $item in $value {
      $columns: grid-get-columns($item);
      $orders: grid-get-orders($item);

      @include grid-row($columns, $orders, $child, $float, $gutter, $max-order, $list-length);

      // get list-length
      @if $columns { 
        $list-length: ($list-length + length($columns)); 
        // clear float on the first column of a new row
        .lt-ie10 & > #{$child}:nth-child(#{$list-length + 1}) { clear: both; }
      }

      // get max-order
      @if $orders {
        @if $max-order {
          $max-order: ($max-order + max($orders...));
        } @else {
          $max-order: max($orders...);
        }
      }
    }

  // single row
  } @else {
    $columns: grid-get-columns($value);
    $orders: grid-get-orders($value);

    @include grid-row($columns, $orders, $child, $float, $gutter);
  }
}

// == grid-row ==
@mixin grid-row($cols, $orders, $child, $float, $gutter, $max-order:false, $list-length:0) {

  $full-col: 0;
  @each $item in $cols {
    $full-col: ($full-col + $item);
  }

  @for $i from 1 through length($cols) {
    // no-flexbox
    $new-orders: ();
    $location: 1;
    @if $orders {
      $new-orders: get-position-from-order($orders);
      $location: index($new-orders, $i);
    } @else {
      $new-orders: (1 2 3 4 5 6 7 8 9 10);
      $location: $i;
    }


    > #{$child}:nth-child(#{$i + $list-length}) {
      // order
      @if $orders or $max-order {
        @if $orders { 
          @if $max-order {
            @include ro-order((nth($orders, $i) + $max-order)); 
          } @else {
            @include ro-order(nth($orders, $i)); 
          }
        } @else {
          @include ro-order($max-order);
        }
      }

      // gutter: %
      @if unit($gutter) == '%' or $gutter == 0 {
        width: ((100% + $gutter) * nth($cols, $i) / $full-col - $gutter);

      // gutter: px | em | rem | vw
      } @else {
        // 1. Add calc support for chrome back to 19+.
        // 2. No need to add -moz- because firefox already support css-calc form 28+ while it full supports flexbox.
        width: -webkit-calc((100% + #{$gutter}) * #{nth($cols, $i)} / #{$full-col} - #{$gutter});
        width: calc((100% + #{$gutter}) * #{nth($cols, $i)} / #{$full-col} - #{$gutter});
      }

      .lt-ie10 & {
        // gutter: px | em | rem | vw
        @if $gutter != 0 and unit($gutter) != '%' { width: percentage(nth($cols, $i) / $full-col); }

        @if $location == 1 {
          margin-#{$float}: 0;
        } @else {
          $val: 0;
          @for $j from 1 to $location {
            $val: ($val + nth($cols, nth($new-orders, $j)));
          }

          // gutter: %
          @if unit($gutter) == '%' or $gutter == 0 {
            margin-#{$float}: ((100% + $gutter) * $val / $full-col);
          // gutter: px | em | rem | vw
          } @else {
            margin-#{$float}: percentage($val / $full-col);
          }
        }
      }
    }
  }
}

// == grid-get-columns ==
@function grid-has-breakpoints($data) {
  @if type-of($data) == 'map' {
    $bools: ();

    @each $bp in map-keys($data) {
      $type: type-of($bp);
      @if $type == 'string' or $type == 'number' and not unitless($bp) {
        $bools: append($bools, true);
      } @else {
        $bools: append($bools, false);
      }
    }

    @return index($bools, false) == null;
  } @else {
    @return false;
  }
}

@function grid-get-columns($value) {
  $columns: false;

  @if type-of($value) == 'list' or type-of($value) == 'number' {
    $columns: $value;
  } @else if type-of($value) == 'map' {
    @if length(map-keys($value)) == 1 {
      $columns: nth(map-keys($value), 1);
    } @else {
      $columns: map-keys($value);
    }
  }

  @return $columns;
}

// == grid-get-orders ==
@function grid-get-orders($value) {
  $orders: false;
  @if type-of($value) == 'map' {
    @if length(map-values($value)) == 1 {
      $orders: nth(map-values($value), 1);
    } @else {
      $orders: map-values($value);
    }
  }

  @if $orders {
    $min-order: min($orders...);
    $new-orders: ();

    @if $min-order <= 0 {
      @each $item in $orders {
        $new-orders: append($new-orders, ($item + abs($min-order) + 1));
      }

      $orders: $new-orders;
    }
  }

  @return $orders;
}