@mixin container($key: false){
  $container: false;
  $breakpoint: false;
  $gutter: args-get-next($key, 'gutter', map-get($ro-layout, 'gutter'));
  $align: args-get($key, (left right center), center);

  // get container & gutter
  @if type-of($key) == 'number' {
    $container: $key;
  } @else if type-of($key) == list {
    @if index($key, 'gutter') {
      $key: list-remove($key, $gutter);
    }
    @each $item in $key {
      @if type-of($item) == 'number'{
        @if not $container {
          $container: $item;
        } @else {
          $gutter: $item;
        }
      } 
    }
  }
  @if not $container { $container: map-get($ro-layout, container); }

  // strip unit when gutter == 0
  @if not $gutter or 
      type-of($gutter) != 'number' or 
      $gutter and strip-unit($gutter) == 0 { 
        $gutter: 0; 
      }

  // *** output *** //
  @extend %clearfix;

  // pecentage container: 80%
  @if unit($container) == '%' {
    padding: 0 ((100% - $container) / 2);

  // px or em container: 1000px 
  } @else {
    max-width: $container;
    @if $gutter != 0 { padding: 0 ($gutter / 2); }
    @if $align == center {
      margin-left: auto;
      margin-right: auto;
    } @else {
      margin-#{$align}: 0;
      margin-#{opposite($align)}: auto;
    }
  }
}