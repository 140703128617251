@mixin holy-grail($key){
  $columns: false;
  $order: false;
  $child: args-get-next($key, 'child', 'div');
  $gutter: args-get-next($key, 'gutter', map-get($ro-layout, 'gutter'));
  $float: if(args-get($key, $ro-text-direction, 'LTR') == 'LTR', left, right);

  $global-bps: args-get-global-breakpoints($key);
  @if $global-bps { $key: list-remove($key, $global-bps); } // remove global-breakpoints from args if exist
  $bps: false;
  $condition: args-get($key, ('min' 'max'), 'min');
  $media-type: args-get($key, $ro-media-type);

  // strip unit when gutter == 0
  @if not $gutter or type-of($gutter) != 'number' or $gutter and strip-unit($gutter) == 0 { $gutter: 0; }

  // get data
  $data: args-get-liquid-data($key, 3);

  @include clearfix(); 

  // using map
  @if type-of($data) == 'map' {

    // simple map: without breakpoint
    // ('auto' 200px 150px : 2 1 1)
    @if length(nth(map-keys($data), 1)) == 3 {
      $columns: nth(map-keys($data), 1);
      $order: nth(map-values($data), 1);
      @include make-holy-grail($columns, $order, $child, $gutter, $float);

    // ('auto' : 2, 200px : 1, 150px : 1)
    } @else if is-simple-map($data) {
      $columns: map-keys($data);
      $order: map-values($data);
      @include make-holy-grail($columns, $order, $child, $gutter, $float);

    // complex map: with breakpoints
    } @else {
      @if $global-bps {
        $data: map-update-breakpoints($data, $global-bps);
      }
      $bps: map-keys($data);

      @each $bp in $bps {
        $layout: map-get($data, $bp);

        @if type-of($layout) == 'list' {
          $columns: $layout;
          @if not $order { $order: (1, 2, 3); }
        } @else if type-of($layout) == 'map' {
          $columns: map-keys($layout);
          $order: map-values(map-get($data, $bp));
        }

        @if $bp == 'default' or $bp == null {
          @include make-holy-grail($columns, $order, $child, $gutter, $float);
        } @else {
          @include bp($condition $media-type $bp) {
            @include make-holy-grail($columns, $order, $child, $gutter, $float);
          }
        }
      }
    }
    
  // using list
  } @else {
    $columns: $data;
    $order: (1, 2, 3);
    @include make-holy-grail($columns, $order, $child, $gutter, $float);
  }

}

@mixin make-holy-grail($columns, $order, $child, $gutter, $float) {
  $columns-num: ();
  @each $item in $columns {
    @if type-of($item) == 'number' { $columns-num: append($columns-num, $item); }
  }

  $column-unit: false;
  @if unit(nth($columns-num, 1)) == unit(nth($columns-num, 2)) {
    $column-unit: unit(nth($columns-num, 1));
  } @else {
    @error 'Please match the units. You passed "#{$columns}".'
  }
  $gutter-calc: if($gutter == 0, 0px, $gutter);
  $gutter-ie8: gutter-fallback($gutter, $column-unit);

  $child-list: ();
  $children-ie8: '';
  @if type-of($child) == 'string' {

    $child-list: ('#{$child}:nth-child(1)', '#{$child}:nth-child(2)', '#{$child}:nth-child(3)');
    $children-ie8: '.lt-ie9 #{&} > #{$child}';
  } @else if type-of($child) == 'list' and length($child) == 3 {

    $child-list: $child;
    $children-ie8: '.lt-ie9 #{&} > #{nth($child, 1)}, .lt-ie9 #{&} > #{nth($child, 2)}, .lt-ie9 #{&} > #{nth($child, 3)}'
  } 

  $positions: get-position-from-order($order);
  $new-columns: ();
  @for $i from 1 through 3 {
    $new-columns: append($new-columns, nth($columns, nth($positions, $i)));
  }

  @at-root #{$children-ie8} {
    box-sizing: border-box;
    position: relative;
  }

  // flexible-column is on the left
  @if nth($new-columns, 1) == null or nth($new-columns, 1) == 'auto' {

    @for $i from 1 through length($new-columns) {
      > #{nth($child-list, nth($positions, $i))} {

        @if ($i == 1) {
          float: $float;
          margin: get-TRBL(opposite($float) -100%);
          // float: left;
          // margin-right: -100%;
          width: 100%;
          width: -webkit-calc(100% - #{nth($new-columns, 2)} - #{nth($new-columns, 3)} - #{$gutter-calc * 2});
          width: -moz-calc(100% - #{nth($new-columns, 2)} - #{nth($new-columns, 3)} - #{$gutter-calc * 2});
          width: calc(100% - #{nth($new-columns, 2)} - #{nth($new-columns, 3)} - #{$gutter-calc * 2});

          .lt-ie9 & {
            padding: get-TRBL(opposite($float) (nth($new-columns, 2) + nth($new-columns, 3) + $gutter-ie8 * 2));
            // padding-right: #{(nth($new-columns, 2) + nth($new-columns, 3) + $gutter * 2)});
            z-index: 0;
          }

        } @else {
          float: opposite($float);
          width: nth($new-columns, $i);
          // float: right;

          @if $i == 2 {
            margin: get-TRBL(opposite($float) (nth($new-columns, 3) + $gutter-ie8) $float -100%);
            margin: get-TRBL(opposite($float) calc(#{nth($new-columns, 3)} + #{$gutter}) $float -100%);
            // margin-left: -100%;
            // margin-right: (nth($new-columns, 3) + $gutter);
          } @else {
            margin: get-TRBL($float -100%);
            // margin-left: -100%;
          }

          .lt-ie9 & { 
            padding: 0; 
            z-index: 1;
          }
        }
      }
    }

  // flexible-column is on the middle
  } @else if nth($new-columns, 2) == null or nth($new-columns, 2) == 'auto' {

    @for $i from 1 through length($new-columns) {
      > #{nth($child-list, nth($positions, $i))} {

        @if ($i == 3) {
          float: opposite($float);
          width: nth($new-columns, $i);
          margin: get-TRBL($float -100%);
          // float: right;
          // margin-left: -100%;

          .lt-ie9 & { 
            padding: 0; 
            z-index: 1;
          }

        } @else {
          float: $float;
          // float: left;

          @if $i == 2 {
            width: 100%;
            width: -webkit-calc(100% - #{nth($new-columns, 1)} - #{nth($new-columns, 3)} - #{$gutter-calc * 2});
            width: -moz-calc(100% - #{nth($new-columns, 1)} - #{nth($new-columns, 3)} - #{$gutter-calc * 2});
            width: calc(100% - #{nth($new-columns, 1)} - #{nth($new-columns, 3)} - #{$gutter-calc * 2});
            margin: get-TRBL(opposite($float) -100% $float calc(#{nth($new-columns, 1)} + #{$gutter}));
            // margin-left: #{(nth($new-columns, 1) + $gutter)};
            // margin-right: -100%;

            .lt-ie9 & {
              padding: get-TRBL(opposite($float) (nth($new-columns, 3) + $gutter-ie8) $float (nth($new-columns, 1) + $gutter-ie8));
              // padding-left: #{(nth($new-columns, 1) + $gutter)};
              // padding-right: #{(nth($new-columns, 3) + $gutter)};
              margin: get-TRBL(opposite($float) -100%);
              // margin-right: -100%;
              z-index: 0;
            }

          } @else {
            width: nth($new-columns, $i);
            margin: get-TRBL(opposite($float) -100%);
            // margin-right: -100%;

            .lt-ie9 & { 
              padding: 0; 
              z-index: 1;
            }
          }
        }
      }
    }

  // flexible-column is on the right
  } @else if nth($new-columns, 3) == null or nth($new-columns, 3) == 'auto' {

    @for $i from 1 through length($new-columns) {
      > #{nth($child-list, nth($positions, $i))} {

        @if ($i == 3) {
          float: opposite($float);
          margin: get-TRBL($float -100%);
          // float: right;
          // margin-left: -100%;
          width: 100%;
          width: -webkit-calc(100% - #{nth($new-columns, 1)} - #{nth($new-columns, 2)} - #{$gutter-calc * 2});
          width: -moz-calc(100% - #{nth($new-columns, 1)} - #{nth($new-columns, 2)} - #{$gutter-calc * 2});
          width: calc(100% - #{nth($new-columns, 1)} - #{nth($new-columns, 2)} - #{$gutter-calc * 2});
          
          .lt-ie9 & {
            padding: get-TRBL($float (nth($new-columns, 1) + nth($new-columns, 2) + $gutter-ie8 * 2));
            // padding-left: (nth($new-columns, 1) + nth($new-columns, 2) + $gutter * 2);
            z-index: 0;
          }

        } @else {
          float: $float;
          width: nth($new-columns, $i);
          // float: left;

          @if $i == 2 {
            margin: get-TRBL(opposite($float) -100% $float (nth($new-columns, 1) + $gutter-ie8));
            margin: get-TRBL(opposite($float) -100% $float calc(#{nth($new-columns, 1)} + #{$gutter}));
            // margin-left: #{(nth($new-columns, 1) + $gutter)};
            // margin-right: -100%;
          } @else {
            margin: get-TRBL(opposite($float) -100%);
            // margin-right: -100%;
          }

          .lt-ie9 & { 
            padding: 0; 
            z-index: 1;
          }
        }
      }
    }
  }

}

@mixin liquid-3($key) {
  @include holy-grail($key);
}