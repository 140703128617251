// *** analogous *** //
@function analogous($key) {
  $color: false;
  $lightness: false;
  
  @each $item in $key {
    @if type-of($item) == color and not $color {
      $color: $item;
    } @else if type-of($item) == number and not $lightness {
      $lightness: $item;
    }
  }

  @if $color {
    @if $lightness {
      @return if(lightness($color) >= 50%, darken($color, $lightness), lighten($color, $lightness)); 
    } @else { 
      @return $color; 
    }
  }
}
