@function list-remove($list, $val){
  $result: ();

  @if type-of($list) != 'list' {
    $result: $list;
  }

  @each $item in $list {
    @if $item != $val {
      $result: append($result, $item);
    }
  }

  // return the only item in list
  @if length($result) == 1 { $result: nth($result, 1); }

  @return $result;
}