// *** sticky-footer *** //
@mixin sticky-footer($key) {
  $main: args-get-type($key, 'string');

  @include ro-flexbox;
  @include ro-flex-direction(column);
  -webkit-box-orient: vertical; // for Chrome, Safari
     -moz-box-orient: vertical; // for Firefox
  min-height: 700px; // vh fallback
  min-height: 100vh;
  // fallback for IE10
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { height: 100vh; }
  > #{$main} { 
    @include ro-flex-grow(1);
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
  }

  .lt-ie10 & {
    min-height: 0;
    > #{$main} { min-height: 500px; }
  }
}