// header
// ==========

.site-header {
  @extend %clearfix;
  padding-bottom: em(20);
}
.nav-toggle-icon {
  font-size: 27px;
  margin-top: 5px;
  float: left;
  margin-right: 10px;

  // increase clickable space
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
  }
}
.header {
  &-top {
    margin-bottom: 1px;
    padding: 20px 0;
    border-bottom: 1px solid $color-border;
  }
  &-logo {
    display: inline-block;
    vertical-align: bottom;
    transition: font-size 0.3s;
    line-height: 38px;
    @include rp-type($type-logo-header);
  }
  &-title {
    @include type(14px);
    display: inline-block;
    margin-left: 10px;
    color: $color-brand;
  }
  &-search {
    input, button {
      height: 32px;
      .lt-ie9 & { line-height: 32px; }
    }
    input {
      float: left;
      border-right-width: 0;
    }
    button {
      @include type(20px center);
      cursor: pointer;
      span:before { vertical-align: middle; }
    }
    &-toggle {
      font-size: 20px;
      margin-top: 10px;
      float: right;
      cursor: pointer;

      // increase clickable space
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: -10px;
        right: -10px;
        top: -10px;
        bottom: -10px;
      }
    }
  }
}
.topline {
  @include type(12px);
  &-wrap { @extend %clearfix; }
  &-date, &-social, .hottopics {
    padding-top: 5px;
    line-height: 26px;
  }
  &-date { 
    float: left;
    @include bp(max 410) {
      .update-time { display: none; }
    }
  }
  &-social {
    float: right;
    font-size: 18px;
    a {
      display: inline-block;
      padding: 0 .3em;
      &:before { line-height: inherit; }
    }
  }
  .hottopics {
    font-weight: bold;
    border-bottom: 1px solid $color-border;
  }
}
.hottopics {
  color: $color-brand;
  a {
    display: inline-block;
    margin-right: 1em;
    color: $color-brand;
  }
}
.page-wrap { 
  border-bottom: 1px solid $color-border; 
  margin-bottom: em(25);
}
.newslist, 
.relatedArticle {
  li {
    @extend %clearfix;
    @include type(15px 1.3);
    padding: 10px 0;
    border-top: 1px solid  #d8d8d8;
  }
}
@include bp('min' $bp-menu) {
  .nav-toggle-icon, .header-search-toggle { display: none; }
  // .site-header {
    // background-color: #fff;
    // &.js-sticky {
    //   margin-top: 120px;
    //   z-index: $zi-site-header;
    //   padding: em(15) 0 0;
    //   box-shadow: rgba(0, 0, 0, 0.2) 0 4px 10px;
    //   .no-boxshadow & {
    //     border-bottom: 1px solid lighten($color-border, 15%);
    //   }
    //   .header {
    //     &-title,
    //     &-search { display: none; }
    //     &-logo > a {
    //       height: 40px;
    //       background-position: 0 -150px;
    //     }
    //   }
    // }
  // }
  .topline-right-wrap { border-top: 1px solid $color-border;}
  .logo-wrap { float: left; }
  .header-search { float: right; }
}
@include bp('max' $bp-menu) {
  .header {
    &-top { padding: 10px 0 5px; }
    &-wrap { 
      padding-bottom: 10px;
      border-bottom: 1px solid $color-border; 
    }
    &-title { display: none; }
    &-search {
      position: relative;
      input, button {
        height: 40px;
        // padding: 0 1.3em;
        .lt-ie9 & { line-height: 40px; }
      }
      input {
        width: 100%;
        // color: #fff;
        // background: #222;
        border-width: 0;
      }
      button {
        position: absolute;
        right: 0;
        top: 0;
        visibility: hidden;
      }
    }
    &-search-wrapper {
      margin-top: 5px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s;
      &.show { max-height: 50px; }
    }
  }
  .topline .hottopics { display: none; }
}