// about
// =========

.about {
  &-wrap {
    @include liquid-2($layout-about);
  }
  &-main, &-aside { margin: 30px 0; }
  &-aside {
    h4 {
      @include type(20px $franklin-boo uppercase);
      padding: 0.3em 0 0.7em;
    }
  }
  &-main {
    > section { 
      margin: 30px 0; 
      &:first-child { margin-top: 0; }
    }
    li {
      @include type(14px 1.7);
      margin-bottom: 1em;
    }
    a {
      color: $color-brand;
      &:hover { color: #888; }
    }
  }
}
.subnav {
  @include type($franklin-boo uppercase);
  li {
    @include type(14px);
    > a {
      display: inline-block;
      padding: 0.5em 0 0.9em;
      border-top: 1px solid $color-border;
      transition: color 0.3s;
    }
  }
  .current > a,
  li > a:hover {
    color: $color-brand;
    border-color: $color-brand;
  }
}
.styled {
  margin-left: 1.5em;
  li {
    @include type(14px 1.7);
    margin-bottom: 1em;
  }
}
ol.styled {
  counter-reset: ol;
  > li:before {
    counter-increment: ol;
    content: counters(ol, '.') ' ';
  }
}
ul.styled {
  li { list-style-type: square; }
}