@import "breakpoint";

// *** hidden *** //
@mixin hidden($key){
  @if type-of($key) == 'number' {
    @include bp('min' $key) { display: none !important; }
  } @else if type-of($key) == list {
    @include bp($key) { display: none !important; }
  }
}
