// *** button *** //
@mixin button ($key: '0.5em 0.8em'){
  $check: append(join($ro-button-style, $ro-button-direction), 'round');

  $padding: false;
  $round: args-get($key, 'round');
  $border-radius: false;

  $direction: args-get($key, $ro-button-direction);
  $style: args-get($key, $ro-button-style);
  $active-selector: ':hover';

  $colors: ();
  $color-1: null;
  $color-2: null;
  @each $item in $key {
    @if type-of($item) == 'color' {
      $colors: append($colors, $item);
    }
  }
  @if length($colors) > 0 {
    $color-1: nth($colors, 1); 
    @if length($colors) > 1 {
      $color-2: nth($colors, 2); 
    } @else {
      $color-2: analogous($color-1 10%); 
    }
  }

  $duration: 0.3s;
  $timing-function: ease;
  @each $item in $key {
    @if type-of($item) == 'number' {
      @if unit($item) == 's' {
        $duration: $item;
      } @else if index($ro-unit, unit($item)) != null {
        $border-radius: $item;
      }
    } @else if type-of($item) == 'string' {
      @if index($ro-timing-function, $item) or str-index($item, 'cubic-bezier') {
        $timing-function: unquote($item);
      } @else if index(('.', '#', ':', '['), str-slice($item, 1, 1)) {
        $active-selector: $item;
      } @else if not index($check, $item) {
        @each $u in $ro-unit {
          @if str-index($item, $u) { $padding: unquote($item); }
        }
      }
    }
  }

  // *** output *** //
  @extend %button-style;
  @if $padding {
    @if not index(('push'), $style) {
      padding: $padding;
    }
  }
  @if $border-radius {
    border-radius: $border-radius;
  }
  @if $round {
    border-radius: $ro-button-round;
  }
  @if $color-1 and not index(('simple' 'veil' 'line-drawing'), $style) {
    background: $color-1;
    color: contrast($color-1);
  }
  @if $colors {
    @if $style {
      @if $style == 'slide' {
        @include button-pos-r();
        &:before {
          @include button-pos-a();
          width: 100%;
          height: 100%;
          @if not $direction { $direction: left; }
          @if index((left right), $direction) {
            top: 0px;
          } @else {
            left: 0px;
          }
          #{$direction}: -100%;
          background: $color-2;
          transition: #{$direction} $duration $timing-function;
        }
        &#{$active-selector} {
          @if contrast($color-1) != contrast($color-2) {
            color: contrast($color-2);
          }
          &:before { #{$direction}: 0px; }
        }
      } @else if $style == 'highlight' {
        transition: background $duration $timing-function, color $duration $timing-function;
        &#{$active-selector} {
          background: $color-2;
          @if contrast($color-1) != contrast($color-2) {
            color: contrast($color-2);
          }
        }
      } @else if $style == 'simple' {
        color: $color-1;
        border: 1px solid $color-1;
        background: transparent;
        transition: background $duration $timing-function, color $duration $timing-function;
        &#{$active-selector} {
          color: contrast($color-1);
          border-color: $color-1;
          background: $color-1;
        }
      } @else if $style == 'ripple' {
        @include button-pos-r();
        &:before {
          @include button-pos-a();
          @include ro-transform(scale(0));
          opacity: 1;
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: -1;
          width: 10em;
          height: 10em;
          margin-left: -5em;
          margin-top: -5em;
          border-radius: 50%;
          background: $color-2;
          @include ro-keyframes(button-ripple) {
            to {
              @include ro-transform(scale(1));
              opacity: 0;
            }
          }
          .lt-ie9 & { display: none; }
        }
        &#{$active-selector}:before {
          @include ro-animation(button-ripple 1s 1);
          .lt-ie9 & { display: block; }
        }
      } @else if $style == 'veil' {
        @include button-pos-r();
        color: $color-1;
        border: 1px solid currentColor;
        .lt-ie9 & { border: 1px solid $color-1; }
        background: transparent;
        transition: background $duration $timing-function, color $duration $timing-function;
        &:before {
          @include button-pos-a();
          @include opacity(0);
          @if $direction == vertical {
            @include ro-transform(scaleY(0.7));
          } @else {
            @include ro-transform(scaleX(0.7));
          }
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          background: $color-1;
          transition: transform $duration $timing-function, opacity $duration $timing-function;
          .lt-ie9 & { display: none; }
        }
        &#{$active-selector} {
          color: contrast($color-1);
          &:before {
            @include opacity(1);
            @if $direction == vertical {
              @include ro-transform(scaleY(1));
            } @else {
              @include ro-transform(scaleX(1));
            }
            .lt-ie9 & { display: block; }
          }
        }
      } @else if $style == 'push' {
        @include button-pos-r();
        span, &:before {
          @if $padding {
            padding: $padding;
          }
          transition: transform $duration $timing-function, opacity $duration $timing-function;
        }
        span {
          @include opacity(1);
          @include ro-transform(translateY(0));
          display: block;
        }
        &:before {
          @include opacity(0);
          @if $direction == bottom {
            @include ro-transform(translateY(-20%));
          } @else if $direction == left {
            @include ro-transform(translateX(20%));
          } @else if $direction == right {
            @include ro-transform(translateX(-20%));
          } @else {
            @include ro-transform(translateY(20%));
          }
          @if $padding {
            padding: $padding;
          }
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          .lt-ie9 & { display: none; }
          content: attr(data-text);
        }
        &#{$active-selector} {
          span {
            @include opacity(0);
            @if $direction == bottom {
              @include ro-transform(translateY(20%));
            } @else if $direction == left {
              @include ro-transform(translateX(-20%));
            } @else if $direction == right {
              @include ro-transform(translateX(20%));
            } @else {
              @include ro-transform(translateY(-20%));
            }
          }
          &:before {
            @include opacity(1);
            @include ro-transform(translateY(0));
            .lt-ie9 & { display: block; }
          }
        }
      } @else if $style == 'cut' {
        @include button-pos-r;
        transition: color $duration $timing-function;
        &:before {
          @include button-pos-a;
          @include ro-transform(rotate3d(0, 0, 1, -45deg) translate3d(0px, -3em, 0px));
          @include ro-transform-origin(0% 100% 0px);
          left: 0px;
          top: 0px;
          width: 150%;
          height: 100%;
          background: $color-2;
          transition: transform $duration $timing-function;
          .lt-ie9 & { display: none; }
        }
        &#{$active-selector} {
          &:before {
            @include ro-transform(rotate3d(0, 0, 1, 0deg));
            .lt-ie9 & { display: block; }
          }
        }
      } @else if $style == 'bubble' {
        @include button-pos-r();
        overflow: visible;
        &:before {
          @include button-pos-a();
          left: 0px;
          right: 0px;
          top: 0px;
          bottom: 0px;
          opacity: 0;
          border: 1px solid $color-1;
          transition: transform $duration $timing-function, opacity $duration $timing-function;
          @if $border-radius { border-radius: $border-radius * 1.3; }
          @include ro-keyframes(button-bubble) {
            60% {
              left: -0.7em;
              right: -0.7em;
              top: -0.7em;
              bottom: -0.7em;
              opacity: 0.8;
            }
            100% {
              left: -1em;
              right: -1em;
              top: -1em;
              bottom: -1em;
              opacity: 0;
            }
          }
        }
        &#{$active-selector}:before { @include ro-animation(button-bubble 0.7s 1); }
      } @else if $style == 'line-drawing' {
        @include button-pos-r();
        span {
          position: absolute;
          display: block;
          box-sizing: border-box;
          background: $color-1;
          transition: width $duration $timing-function, height $duration $timing-function;
          &:nth-child(1) {
            left: 0px;
            top: 0px;
          }
          &:nth-child(2) {
            right: 0px;
            bottom: 0px;
          }
          &:nth-child(3) {
            top: 0px;
            right: 0px;
          }
          &:nth-child(4) {
            left: 0px;
            bottom: 0px;
          }
          &:nth-child(1), 
          &:nth-child(2) {
            width: 0px;
            height: 1px;
          }
          &:nth-child(3), 
          &:nth-child(4) {
            width: 1px;
            height: 0px;
          }
        }
        &#{$active-selector} {
          span {
            &:nth-child(1),
            &:nth-child(2) { width: 100%; }
            &:nth-child(3),
            &:nth-child(4) { height: 100%; }
          }
        }
      } @else if $style == 'shake' {
        &#{$active-selector} { @include shake(); }
      } @else if $style == 'new' {
      }
    } 
  }
}

@mixin button-pos-r (){
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@mixin button-pos-a (){
  content: '';
  position: absolute;
  z-index: -1;
}

@mixin shake($key: false){
  $duration: 0.3s;
  @if $key {
    $duration: $key;
  }
  @include ro-keyframes(button-shake) {
    0%, 100% { transform: translateX(0);}
    16.6%, 50%, 83.3% { transform: translateX(-0.6em);}
    33.3%, 66.6% { transform: translateX(0.6em);}
  }
  @include ro-animation(button-shake $duration 1); 
}

