// form
// ============

input, button {
  height: 36px;
  .lt-ie9 & { line-height: 36px; }
}
input, button, textarea {
  @include type(14px);
  padding: 0 12px;
  box-sizing: border-box;
}
input, textarea {
  border: 1px solid $color-border;
}
button {
  border-width: 0;
  color: #fff;
  background: $color-brand;
}
.form-combined {
  @extend %clearfix;
  > input,
  > button {
    display: block;
    float: left;
    box-sizing: border-box;
  }
  :first-child {
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  :last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}