// *** slider-carousel *** //
@mixin slider-carousel($key){
  $items: 1;
  $perpage: 1;
  $gutter: args-get-next($key, 'gutter', 10px);

  // options
  $center: args-get($key, 'center');
  $bypage: args-get($key, 'bypage');
  $style: args-get($key, $ro-slider-carousel-style);
  $keep: args-get($key, 'keep');

  // vertical
  $vertical: args-get($key, 'vertical');
  $ratio: args-get-next($key, 'ratio', 9/16);

  // autoplay
  $autoplay: args-get($key, 'autoplay');
  $autoplay-js: args-get($key, 'autoplay-js');
  $progress-bar: args-get($key, 'progress-bar');
  $hoverpause: args-get($key, 'hoverpause');
  $speed: args-get-next($key, 'speed', 1s);
  $timeout: args-get-next($key, 'timeout', 3s);
  $time-per-item: ($speed + $timeout);

  @if type-of($key) == 'number' {
    $items: $key;
  } @else if type-of($key) == list {
    @each $val in $key {

      @if index($key, by) {
        $items: args-get-prev($key, by);
        $perpage: args-get-next($key, by);
      } @else if type-of($val) == 'number' and unitless($val) {
        $items: $val;
      }
    }
  }

  $half: 0;
  $pages: $items - $perpage + 1;
  $prefix: get-slider-id(&);
  $id: "#" + $prefix;
  @if $center and $perpage > 1 { 
    $half: (1 / ($perpage * 2)); 
  }
  @if unit($ratio) != '%' {
    $ratio: percentage($ratio);
  }

  @if not $keep {
    position: relative;
    > input { position: absolute; left: -9999px; }
    .outer { 
      overflow: hidden;  
      transition: height ($speed / 3); 
      @if $vertical {
        height: 0;
        margin: 0;
        padding-bottom: $ratio;
      } @else {
        @if $gutter and $gutter != 0 {
          @if not $center { margin: 0 (- $gutter / 2); }
        }
      }
    }
    .inner {
      @include clearfix();
      @if $vertical {
        position: static;
        white-space: normal;
        transition: margin-top $speed; 
      } @else {
        position: relative;
        letter-spacing: -0.34em; // remove white space between inline-block elements
        white-space: nowrap;
        transition: left $speed; 
      }
      > li {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        @if $vertical {
          height: 0;
          display: block;
          padding: 0 0 $ratio;
        } @else {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          letter-spacing: 0; // restore letter-spacing
          white-space: normal;
          @if $gutter and $gutter != 0 {
            padding: 0 ($gutter / 2); 
          }
        }
      }
    }
    .controls, .dots, .autoplay { z-index: 2; }
    .controls {
      span { display: none; }
      .prev { float: left; }
      .next { float: right; }
    }
    .dots {
      letter-spacing: -0.34em; // remove white space between inline-block elements
      > label { letter-spacing: 0; } // restore letter-spacing
      > label, span {
        display: inline-block;
        vertical-align: top;
      }
      .normal { display: block; }
      .active { display: none; }
    }
    .autoplay {
      @if $autoplay { 
        .lt-ie10 & { display: none !important; } 
      }
      .pause {display: none; }
    }
  }

  // per page
  @if $perpage != 1 { 
    @if $vertical {
      .outer { padding-bottom: ($ratio * $perpage); } 
    } @else {
      .inner > li { width: percentage(1 / $perpage); } 
    }
  }
  
  // set inner
  @for $i from 1 through $items {
    @at-root {
      #{$id}-#{$i}:checked ~ {
        .outer > .inner { 
          @if $vertical {
            margin-top: - ($ratio * ($i - 1));
          } @else {
            margin-top: 0;
            @if ($i / $perpage) >= floor($items / $perpage) and 
                ($i / $perpage) < ceil($items / $perpage) {
              left: - percentage(($items / $perpage) - 1 - $half);
            } @else {
              left: - percentage(($i - 1) / $perpage + $half); 
            }
          }
        }
      }
    }
  }

  // style
  $scale-val: '';
  $rotate-val-left: '';
  $rotate-val-right: '';
  @if $style == 'scale' {
    $scale-val: scale(0.75);
  } @else if $style == 'rotate' {
    $rotate-val-left: perspective(600px) scale(0.85) rotateY(-20deg);
    $rotate-val-right: perspective(600px) scale(0.85) rotateY(20deg);
  }
  @if index(('scale' 'rotate'), $style) {
    .inner > li { @include ro-transition(transform $speed); }

    @for $i from 1 through $items {
      #{$id}-#{$i}:checked ~ {
        .outer > .inner > li { 
          $left-num: false;
          $right-num: false;
          $center-num: false;

          @if $perpage == 1 {
            @if $style == 'rotate' {
              $left-num: $i - 1;
              $right-num: $i + 1;
            }
            $center-num: $i;
          } @else if $perpage < 5 {
            $add: false;
            // odd
            @if ceil($perpage / 2) > ($perpage / 2) {
              @if $style == 'rotate' {
                $left-num: $i + (($perpage - 1) / 2) - 1;
                $right-num: $i + (($perpage - 1) / 2) + 1;
              }

              $center-num: $i + (($perpage - 1) / 2);
            } @else {
              @if $style == 'rotate' {
                $left-num: $i + (($perpage / 2) - 1) - 1;
                $right-num: $i + (($perpage / 2) - 1) + 2;
              }

              $center-num: $i + (($perpage / 2) - 1);
            }
          } 
          // left, right
          @if $style == 'scale' {
            &:nth-child(n) { @include ro-transform(#{$scale-val}); }
          } @else if $style == 'rotate' {
            &:nth-child(-n + #{$left-num}) { @include ro-transform(#{$rotate-val-left}); }
            &:nth-child(n + #{$right-num}) { @include ro-transform(#{$rotate-val-right}); }
          }
          // center
          @if $perpage < 5 and ceil($perpage / 2) == ($perpage / 2) {
            &:nth-child(#{$center-num}), 
            &:nth-child(#{$center-num + 1}) { @include ro-transform(scale(1) rotateY(0deg)); }
          } @else {
            &:nth-child(#{$center-num}) { @include ro-transform(scale(1) rotateY(0deg)); }
          }
        }
      }
    }
  } @else {
    @for $i from 1 through $items {
      #{$id}-#{$i}:checked ~ .outer > .inner > li:nth-child(n) {
        @include ro-transform(scale(1) rotateY(0deg));
      }
    }
  }

  @at-root (without: media) { 
    @at-root {
      // active dots
      @for $i from 1 through $items {
        #{$id}-#{$i}:checked ~ {
            .dots label:nth-child(#{$i}) .active { display: block; }
            .dots label:nth-child(#{$i}) .normal { display: none; }
        }
      }
      #{$id}-autoplay:checked ~ {
        @if $autoplay {
          // hide controls and dots
          .controls, .dots {
            display: none; 
            .lt-ie10 & { display: block !important; }
          }
        }
        .autoplay {
          .play { display: none; }
          .pause { display: block; }
        }
      }
    }
  }
  // hide extra dots
  @if $center {
    .dots label:nth-child(n + #{$pages}) { display: none; }
  } @else {
    .dots label:nth-child(n + #{$pages + 1}) { display: none; }
  }

  // set autoplay animation
  @if $autoplay {
    $left-val: 0;
    $frames: 1;

    @include ro-keyframes(#{$prefix}-inner) {
      @if $bypage { $frames: ceil($items/$perpage); } 
      @else { $frames: ($items - $perpage + 1); }
      
      // vertical
      @if $vertical {
        0% { margin-top: 0; }
        
        @if $bypage {
          @for $i from 1 through $frames {
            #{percentage(($timeout + ($time-per-item * ($i - 1))) / ($time-per-item * $frames))} { 
              @if ($perpage * ($i - 1)) > ($items - $perpage) {
                margin-top: - ($ratio * ($items - $perpage)); 
              } @else {
                margin-top: - ($ratio * $perpage * ($i - 1)); 
              }
            }
            #{percentage(($time-per-item * $i) / ($time-per-item * $frames))} { 
              @if ($perpage * $i) > ($items - $perpage) {
                margin-top: - ($ratio * ($items - $perpage)); 
              } @else {
                margin-top: - ($ratio * $perpage * $i); 
              }
            }
          }
        } @else {
          @for $i from 1 through $frames {
            #{percentage(($timeout + ($time-per-item * ($i - 1))) / ($time-per-item * $frames))} { 
              margin-top: - ($ratio * ($i - 1)); 
            }
            #{percentage(($time-per-item * $i) / ($time-per-item * $frames))} { 
              margin-top: - ($ratio * $i); 
            }
          }
        }

        100% { margin-top: 0; }
      // horizontal
      } @else {
        0% { left: 0; }

        @if $bypage {
          @for $i from 1 through $frames {
            #{percentage(($timeout + ($time-per-item * ($i - 1))) / ($time-per-item * $frames))} { 
              @if (($i - 1) * $perpage) > ($items - $perpage) {
                left: - percentage(($items / $perpage) - 1);
              } @else {
                left: - percentage(($i - 1)); 
              }
            }
            #{percentage(($time-per-item * $i) / ($time-per-item * $frames))} { 
              @if ($i * $perpage) > ($items - $perpage) {
                left: - percentage(($items / $perpage) - 1);
              } @else {
                left: - percentage($i); 
              }
            }
          }
        } @else {
          @for $i from 1 through $frames {
            #{percentage(($timeout + ($time-per-item * ($i - 1))) / ($time-per-item * $frames))} { left: - percentage(($i - 1) / $perpage); }
            #{percentage(($time-per-item * $i) / ($time-per-item * $frames))} { left: - percentage($i / $perpage); }
          }
        }

        100% { left: 0; }
      }
    }
    
    // progress-bar keyframes
    @if $progress-bar {
      @include ro-keyframes(#{$prefix}-progress) {
        0% { width: 0; }
        #{percentage($timeout / $time-per-item)} { width: 100%; }
        #{percentage($timeout / $time-per-item + 0.0001)} { width: 0; }
        100% { width: 0; }
      }
    }

    @at-root {
      #{$id}-autoplay:checked ~ {
        .outer > .inner { @include ro-animation(#{$prefix}-inner $time-per-item * $frames ease infinite); }
        @if $progress-bar {
          .autoplay > .autoplay-progress { @include ro-animation( #{$prefix}-progress $time-per-item ease infinite); }
        }
      }
    }
    // pause on hover
    @if $hoverpause {
      &:hover {
        #{$id}-autoplay ~ {
          .outer > .inner { @include ro-animation-play-state(paused); }
          @if $progress-bar {
            .autoplay > .autoplay-progress { @include ro-animation-play-state(paused); }
          }
        }
      }
    }
  }

  // set controls
  @at-root {
    @for $i from 1 through $items {
      #{$id}-#{$i}:checked ~ {
        .controls label:nth-child(n+1) { display: none; }
        @if $bypage == true {
          // prev
          @if $i == 1 {
            .controls label:nth-child(#{$items - $perpage + 1}),
            .controls label:nth-child(#{$items - $perpage + 1}) .prev { display: block; }
          }
          @else if $i > 1 and $i <= $perpage {
            .controls label:nth-child(1),
            .controls label:nth-child(1) .prev { display: block; }
          }
          @else {
            .controls label:nth-child(#{$i - $perpage}),
            .controls label:nth-child(#{$i - $perpage}) .prev { display: block; }
          }
          // next
          @if $i >= ($items - $perpage + 1) {
            .controls label:nth-child(1),
            .controls label:nth-child(1) .next { display: block; }
          }
          @else if $i > ($items - $perpage * 2 + 1) and $i < ($items - $perpage + 1) {
            .controls label:nth-child(#{$items - $perpage + 1}),
            .controls label:nth-child(#{$items - $perpage + 1}) .next { display: block; }
          }
          @else {
            .controls label:nth-child(#{$i + $perpage}),
            .controls label:nth-child(#{$i + $perpage}) .next { display: block; }
          }
        }
        @else if $center == true {
          @if $i == 1 {
            .controls label:nth-child(#{$pages}), 
            .controls label:nth-child(#{$i + 1}),
            .controls label:nth-child(#{$pages}) .prev, 
            .controls label:nth-child(#{$i + 1}) .next { display: block; }
          }
          @else if $i < ($pages - 1 ) {
            .controls label:nth-child(#{$i - 1}), 
            .controls label:nth-child(#{$i + 1}),
            .controls label:nth-child(#{$i - 1}) .prev, 
            .controls label:nth-child(#{$i + 1}) .next { display: block; }
          }
          @else {
            .controls label:nth-child(#{$i - 1}), 
            .controls label:nth-child(1),
            .controls label:nth-child(#{$i - 1}) .prev, 
            .controls label:nth-child(1) .next { display: block; }
          }
        }
        @else {
          @if $i == 1 {
            .controls label:nth-child(#{$pages}), 
            .controls label:nth-child(#{$i + 1}),
            .controls label:nth-child(#{$pages}) .prev, 
            .controls label:nth-child(#{$i + 1}) .next { display: block; }
          }
          @else if $i < $pages {
            .controls label:nth-child(#{$i - 1}), 
            .controls label:nth-child(#{$i + 1}),
            .controls label:nth-child(#{$i - 1}) .prev, 
            .controls label:nth-child(#{$i + 1}) .next { display: block; }
          }
          @else {
            .controls label:nth-child(#{$i - 1}), 
            .controls label:nth-child(1),
            .controls label:nth-child(#{$i - 1}) .prev, 
            .controls label:nth-child(1) .next { display: block; }
          }
        }
      }
    }
  }

}
