@function get-slider-id($selector){
  $id: '';
  // selector to string
  @each $item in $selector {
    $id: $id + $item + ' ';
  }
  $id: str-slice($id, 1, str-length($id) - 1);
  
  // get the last class name
  @while (str-index($id, '.')) { 
    $id: str-slice($id, str-index($id, '.') + 1, str-length($id)); 
  }
  
  // get the last id name
  @while (str-index($id, '#')) { 
    $id: str-slice($id, str-index($id, '#') + 1, str-length($id)); 
  }
  
  // if no id or class, return the whole selector as id
  @return $id;
}