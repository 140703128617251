@function args-get($key, $arg, $def:false){
  $result: $def;

  // string: return true/false when exists
  @if type-of($arg) == 'string' {
    @if index($key, $arg) {
      $result: true;
    } 
  } @else if type-of($arg) == 'list' {

    // list: return item itself when the item exists
    @each $item in $arg {
      @if index($key, $item) {
        $result: $item;
      } 
    }
  }

  @return $result;
}
