// *** responsive-type *** //
@mixin responsive-type($key) {
  $global-bps: args-get-global-breakpoints($key);
  @if $global-bps { $key: list-remove($key, $global-bps); } // remove global-breakpoints from args if exist
  $bps: false;
  $condition: args-get($key, ('min' 'max'), 'min');
  $media-type: args-get($key, $ro-media-type);

  // get map
  $map: args-get-type($key, 'map');

  // *** output *** //
  @if $map {
    // update breakpoints
    @if $global-bps { $map: map-update-breakpoints($map, $global-bps); }

    @each $bp, $type in $map {
      @if $bp == 'default' or $bp == null {
        @include type($type);
      } @else {
        @include bp($condition $bp) {
          @include type($type);
        }
      }
    }
  }
}


@mixin rp-type($key) {
  @include responsive-type($key);
}