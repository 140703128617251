@function is-simple-map($map) {
  @if type-of($map) != 'map' {
    @return false;
  }
  
  $is-simple-map: true;
  $values: map-values($map);

  @each $item in $values {
    // no need for further check if false
    @if $is-simple-map and not index(('string' 'number' 'null' 'bool'), type-of($item)) {
      $is-simple-map: false;
    }
  }

  @return $is-simple-map;
}
