@import "core";

// *** adjacent *** //
@function adjacent($key){
  $key-new: ();

  @if type-of($key) == 'list' {
    $key-new: append($key, adjacent);
  } @else {
    $key-new: ($key adjacent);
  }

  @return color($key-new);
}