@import "breakpoint";

// *** visible *** //
@mixin visible($key){
  $new-key: ();

  @if type-of($key) == 'number' {
    @include bp('max' $key) { display: none !important; }
  } @else if type-of($key) == list {
    @if nth($key, 1) == 0 {
      $key: list-remove($key, 0);
    } @else {
      $new-key: append($new-key, 0);
    }

    // append $key to $new-key 
    @each $item in $key {
      $new-key: append($new-key, $item);
    }

    @include bp($new-key) { display: none !important; }
  }
}
