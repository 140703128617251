@function list-get-max($list) {
  $_list: ();
  $max: false;

  @if type-of($list) == 'list' {
    @each $item in $list {
      @if type-of($item) == 'number' {
        $_list: append($_list, $item);
      }
    }
    $max: max($_list...);
    @return $max;
  } @else {
    @warn '"#{$list}" is not a list.'
  }
}