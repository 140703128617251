@function is-number-list($list) {
  @if type-of($list) != 'list' {
    @warn '"#{$list}" is not a list';
    @return false;
  } 

  $is-number-list: true;
  @each $item in $list {
    // if $is-number-list: false, no need to check anymore
    @if $is-number-list and type-of($item) != 'number' {
      // check sub list
      @if type-of($item) == 'list' {
        $is-number-list: is-number-list($item);
      } @else {
        $is-number-list: false;
      }
    }
  }

  @return $is-number-list;
}