// variables
// ===========

// layout
$ro-layout: (
  container: 1100px,
  gutter: 30px
);

// breakpoints
$bp-menu: 890px;
$bp-mobile: 640px;

$layout-home: (
  $bp-mobile: (63 37) 1,
  1024px: (43 24 33)
);

$layout-section: (
  980px: (null 400px)
);

$layout-about: (
  768px: (200px null)
);

// gutters
$footer-padding: 12px;
$gutter: 30px;

// font
$franklin-med: "'FranklinGothicURW-Med', Helvetica, Arial, sans-serif";
$franklin-boo: "'FranklinGothicURW-Boo', Helvetica, Arial, sans-serif";
$helvetica: "'Helvetica Neue', Helvetica, Arial, sans-serif";
$arial: "Arial, Helvetica, sans-serif";
$opensans: "'Open Sans', Arial, sans-serif";
$lato: "'Lato', Arial, sans-serif";
$oswald: "'Oswald', Arial, sans-serif";
$georgia: "Georgia, 'Times New Roman', Times, Baskerville, serif";
$times: "'Times New Roman', Times, Baskerville, Georgia, serif";

// color
$color-brand: #E41E03;
$color-link: #238ADC;
$color-border: #ccc;

$social: (
  facebook-like: #4660b5, 
  facebook: #39579a, 
  twitter: #23cbff, 
  pinterest: #df2109, 
  google: #567EF0, 
  google-plus: #DA4835, 
  linkedin: #005987, 
  rss: #EF922F, 
  email: #1D90DD, 
  primary: #0078E7
) !default;
$alerts:(
  success: #3A945B,
  info: #2270C1,
  warning: #DE770F,
  alert: #DE2D0F
) !default;

// z-index
$zi-site-header: 10;
$zi-menu: 10;

$type-logo-header: (
  null: (23px weight-normal uppercase $georgia),
  385px: 30px,
  540px: 38px,
  $bp-menu: (42px 1)
);
$type-logo-footer: (
  null: (24px weight-normal $georgia),
  385px: 28px
);
$type-menu: (
  null: (15px uppercase $franklin-med),
  1024px: 17px
);