// common
// ===============

.container { @include container(gutter 16px); }
.clearfix { @extend %clearfix; }
.figure-right {
  float: right;
  margin-left: 12px;
}
.figure-left {
  float: left;
  margin-right: 12px;
}

// typography
.summary {
  font-family: $arial;
  font-size: 13px;
  line-height: 1.4;
  color: #444;
  margin-bottom: .8em;
}
.heading {
  &-column {
    @include type(18px uppercase weight-normal $franklin-boo);
    padding: 0.5em 0;
    color: $color-brand;
  }
  &-topnews { 
    @include type(22px 1.1 weight-normal $franklin-med); 
    margin-bottom: 0.5em;
  }
  @include bp(min 500) {
    &-topnews { @include type(26px 1.1); }
  }
  &1 { @include type(31px 1.2 $georgia); 
    @include bp('max' $bp-mobile) { @include type(24px); }
  }
  &3 { @include type(20px 1.2); margin-bottom: em(5, 22);}
}
p { @include type(14px 1.3); 
  margin-bottom: 1em;
  &:last-child { margin-bottom: 0; }
}
time { @include type(13px); 
  .news-list-section & {
    display: block;
    margin-bottom: em(5, 13);
    color: #888;
  }
}
.about-main {
  h1, h2, h3, h4 { margin-bottom: 0.5em; }
  h1 { @include type(32px); }
  h2 { @include type(24px uppercase)}
  h3 { @include type(18.5px capitalize); }
  h4 { @include type(16px capitalize); }
  p { 
    max-width: 50em; 
    line-height: 1.7;
  }
  address {
    @include type(14px 1.5);
    margin-bottom: 1em;
    color: #888;
    > strong { color: #666; }
  }
}