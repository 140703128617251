// *** switch *** //
@mixin switch($key: 20px){
  $radius: args-get($key, 'radius');
  $round: args-get($key, 'round');
  $style: args-get($key, $ro-switch-style, 'toggle');
  $default-color: #ddd;
  $active-color: args-get-type($key, 'color', false, #3DD754);
  $text: args-get-next($key, 'text');
  
  // change em, rem to px
  $width: false;
  $height: false;
  @each $item in $key {
    @if type-of($item) == 'number' {
      @if not $width {
        $width: $item;
      } @else {
        $height: $item;
      }
    }
  }
  @if not $width { $width: em(20); }
  @if not $height { $height: $width; }
  $width: em($width);
  $height: em($height);
  $border: ($height / 8);
  $radius-val: ($height / 6);

  // hide checkbox or radio
  > input {
    position: absolute; 
    left: -9999px;
  }

  @if $text {
    label > span {
      position: absolute;
      left: $border;
      top: $border;
      display: inline-block;
      white-space: nowrap;
      color: contrast($default-color);
      transition: color 0.3s;
      .lt-ie9 & { color: #fff; }
      &:before, &:after {
        display: inline-block;
        line-height: $height;
        width: $width;
        text-align: center;
      }
      &:before { content: '#{nth($text, 1)}'; }
      &:after { content: '#{nth($text, 2)}'; }
    }
  }

  > input {
    // normal style
    ~ label {
      display: inline-block;
      height: $height;
      line-height: $height;
      transition: padding 0.3s, background 0.3s;

      @if $style == 'toggle' {
        background: $default-color;
        padding: $border ($height * 0.85) $border $border;
      } @else if $style == 'slider' {
        $width: $width * 1.8;
        text-align: left;
        position: relative;
        padding: 0 $width 0 0;
        &:before, &:after { transition: background 0.3s; }
        &:before {
          content: '';
          width: $width;
          height: 1px;
          position: absolute;
          left: ($height / 2);
          top: ($height / 2);
          z-index: -1;
          background: $default-color;
          .lt-ie9 & { background: $active-color; }
        }
      }

      @if $radius {
        border-radius: $radius-val;
      } @else if $round {
        border-radius: $ro-button-round;
      }

      @if $text {
        position: relative;
        width: $width;
        padding-right: ($width * 2 - $height * 0.85);
      }

      &:after {
        content: '';
        display: inline-block;
        height: $height;
        background: #fff;

        @if $style == 'toggle' {
          width: $width;
          box-shadow: 0 $border $border 0 rgba(0, 0, 0, 0.2);
        } @else if $style == 'slider' {
          width: $height;
          box-shadow: 0 ($border / 1.3) $border 0 rgba(0, 0, 0, 0.2);
          .lt-ie9 & { background: $active-color; }
        }

        @if $radius {
          border-radius: $radius-val;
        } @else if $round {
          border-radius: $ro-button-round;
        }

        @if $text { position: relative; }
      }
    }

    // active style
    &:checked ~ label {
      padding: $border $border $border ($height * 0.85);
      @if $style == 'toggle' {
        background: $active-color;
      } @else if $style == 'slider' {
        padding: 0 0 0 $width;
        &:before, &:after {
          background: $active-color;
        }
      }

      @if $text {
        padding-left: ($width * 2 - $height * 0.85);
        > span { color: contrast($active-color); }
      }
    }
  }
}
