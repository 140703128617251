// *** sticky *** //
// v0.1.4 //

.js-sticky {
  box-sizing: border-box;
}
.js-sticky-container, 
.sticky-container {
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}