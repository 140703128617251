// *** off-canvas *** //
@mixin off-canvas($key) {
  $mode: args-get($key, $ro-off-canvas-modes, 'slide-in');
  $direction: args-get($key, $ro-off-canvas-direction, 'left');
  $submenu: args-get($key, 'submenu');
  $overlay-color: args-get-type($key, 'color', false, rgba(0, 0, 0, 0.2));

  $global-bps: args-get-global-breakpoints($key);
  @if $global-bps { $key: list-remove($key, $global-bps); } // remove global-breakpoints from args if exist
  $bps: false;
  $condition: args-get($key, ('min' 'max'), 'min');
  $media-type: args-get($key, $ro-media-type);

  // get data
  $nav: false;
  $map: false;
  $duration: 0.3s;
  $width: 240px;
  @if type-of($key) == 'map' {
    $map: $key;
  } @else if type-of($key) == 'string' {
    @if index(('.', '#', '['), str-slice($key, 1, 1)) { $nav: $key; }
  } @else if type-of($key) == 'list' {
    @each $item in $key {
      @if type-of($item) == 'map' {
        @if not $map { $map: $item; }
      } @else if type-of($item) == 'number' {
        @if unit($item) == 's' {
          $duration: $item;
        } @else {
          $width: $item;
        }
      } @else if type-of($item) == 'string' {
        @if index(('.', '#', '['), str-slice($item, 1, 1)) { $nav: $item; }
      }
    }
  }

  // update map breakpoints
  @if $global-bps and $map { $map: map-update-breakpoints($map, $global-bps); }

  // define modules
  $page-push: false;
  $page-over-nav: false;
  $page-transform: false;

  $nav-push: false;
  $nav-push-val: false;
  $nav-transform: false;
  $nav-outside: false;

  $overlay: true;
  $overlay-push: false;

  $horizontal: false;
  $vertical: false;

  $transform-origin: false;
  $rotate: false;
  $rotate-default: false;
  $scale-default: translate3d(0px, 0px, 0px);
  $scale-active: perspective(1000px) translate3d(0px, 0px, -300px);

  @if index(('reveal', 'push', 'slide-along', 'slide-out', 'rotate-in', 'rotate-out', 'rotate-in-reverse', 'scale-up'), $mode) {
    $page-push: true;
  }
  @if index(('reveal', 'slide-along', 'slide-out', 'scale-up'), $mode) {
    $page-over-nav: true;
  }
  @if index(('scale-down', 'open-door'), $mode) {
    $page-transform: true;
  }

  @if index(('slide-in', 'slide-along', 'slide-out', 'rotate-in', 'rotate-out', 'rotate-in-reverse', 'scale-down', 'open-door', 'push', 'drawer'), $mode) {
    $nav-push: true;
  }
  @if index(('slide-in', 'rotate-in', 'rotate-out', 'rotate-in-reverse', 'scale-down', 'open-door', 'push', 'drawer'), $mode) {
    $nav-push-val: - $width;
  } @else if index(('slide-along'), $mode) {
    $nav-push-val: - ($width / 2);
  } @else if index(('slide-out'), $mode) {
    $nav-push-val: ($width / 2);
  }
  @if index(('rotate-in', 'rotate-out', 'rotate-in-reverse', 'scale-up'), $mode) {
    $nav-transform: true;
  }
  @if $page-over-nav or index(('scale-down', 'open-door'), $mode) {
    $nav-outside: true;
  }

  @if index(('slide-in', 'rotate-in', 'rotate-out', 'rotate-in-reverse', 'scale-down', 'open-door', 'scale-up', 'push', 'drawer'), $mode) {
    $overlay: true;
  }
  @if index(('slide-along', 'slide-out', 'rotate-in', 'rotate-in-reverse', 'rotate-out', 'reveal', 'scale-up', 'push'), $mode) {
    $overlay-push: true;
  }

  @if index((left, right), $direction) {
    $horizontal: true;
  } @else if index((top, bottom), $direction) {
    $vertical: true;
  }

  @if index(('rotate-in', 'rotate-out', 'rotate-in-reverse'), $mode) {
    $rotate: true;
  }
  @if index(('rotate-in', 'rotate-out', 'open-door'), $mode) {
    @if $direction == left {
      $transform-origin: 100% 50% 0px;
    } @else if $direction == right {
      $transform-origin: 0 50% 0px;
    } @else if $direction == top {
      $transform-origin: 50% 100% 0px;
    } @else if $direction == bottom {
      $transform-origin: 50% 0 0px;
    }
  }
  @if index(('rotate-in-reverse', 'scale-up'), $mode) {
    @if $direction == left {
      $transform-origin: 0 50% 0px;
    } @else if $direction == right {
      $transform-origin: 100% 50% 0px;
    } @else if $direction == top {
      $transform-origin: 50% 0 0px;
    } @else if $direction == bottom {
      $transform-origin: 50% 100% 0px;
    }
  }
  @if index(('rotate-in'), $mode) {
    @if $direction == left {
      $rotate-default: perspective(#{$width}) rotateY(-90deg);
    } @else if $direction == right {
      $rotate-default: perspective(#{$width}) rotateY(90deg);
    } @else if $direction == top {
      $rotate-default: perspective(#{$width}) rotateX(90deg);
    } @else if $direction == bottom {
      $rotate-default: perspective(#{$width}) rotateX(-90deg);
    }
  }
  @if index(('rotate-out', 'rotate-in-reverse'), $mode) {
    @if $direction == left {
      $rotate-default: perspective(#{$width}) rotateY(90deg);
    } @else if $direction == right {
      $rotate-default: perspective(#{$width}) rotateY(-90deg);
    } @else if $direction == top {
      $rotate-default: perspective(#{$width}) rotateX(-90deg);
    } @else if $direction == bottom {
      $rotate-default: perspective(#{$width}) rotateX(90deg);
    }
  }

  // *** output *** //
  // page
  @if $page-transform {
    @include ro-transition(unquote("transform #{$duration}"));
  }

  @if $page-push {
    @include ro-transition(unquote("#{$direction} #{$duration}"));
    position: relative;
    #{$direction}: 0;

    @if $page-over-nav {
      min-height: 100vh;
      z-index: map-get($ro-off-canvas-z-index, 'page');
    }

    input:checked ~ & { #{$direction}: $width; }
  }

  @if $transform-origin and $page-transform {
    @include ro-transform-origin($transform-origin);
    @include ro-transform-style(preserve-3d);
  }

  @if $mode == 'scale-down' {
    @include ro-transform-style(preserve-3d);
    @include ro-transform($scale-default);
    input:checked ~ & {
      @include ro-transform($scale-active);
    }
  } @else if $mode == 'open-door' {
    @include ro-transform(perspective(1000px) rotateY(0deg));
    input:checked ~ & {
      @if $direction == left {
        @include ro-transform(perspective(1000px) rotateY(-7deg));
      } @else if $direction == right {
        @include ro-transform(perspective(1000px) rotateY(7deg));
      } @else if $direction == top {
        @include ro-transform(perspective(1000px) rotateX(7deg));
      } @else if $direction == bottom {
        @include ro-transform(perspective(1000px) rotateX(-7deg));
      }
    }
  }

  @if $submenu {
    // hide submenu on hide nav
    input:not(:checked) ~ & #{$nav} [data-submenu] { #{$direction}: - $width; }
  }
  

  // nav
  @at-root {
    #{$nav} {
      position: fixed;
      display: block;
      z-index: map-get($ro-off-canvas-z-index, 'nav');
      box-sizing: border-box;
      overflow-x: hidden;
      #{$direction}: 0;
      // > ul { position: relative; }

      @if $nav-push and $nav-transform {
        @include ro-transition(unquote("transform #{$duration}"), unquote("margin-#{$direction} #{$duration}"));
      } @else if $nav-push and not $nav-transform {
        @include ro-transition(unquote("margin-#{$direction} #{$duration}"));
      } @else if not $nav-push and $nav-transform {
        @include ro-transition(unquote("transform #{$duration}"));
      }

      @if $nav-push-val {
        margin-#{$direction}: $nav-push-val;
      }

      @if $horizontal {
        width: $width;
        bottom: 0;
        overflow-x: hidden;
        @if $mode == 'drawer' {
          @include nav-map(top, $map); // set top value 
        } @else {
          top: 0;
        }
      } @else if $vertical {
        height: $width;
        left: 0;
        right: 0;
        overflow-y: hidden;
      }

      @if $transform-origin and $nav-transform {
        @include ro-transform-origin($transform-origin);
        @include ro-transform-style(preserve-3d);
      }
      @if $rotate {
        @include ro-transform($rotate-default);
      }

      @if $mode == 'scale-up' {
        @include ro-transform($scale-active);
        input:checked ~ & {
          @include ro-transform($scale-default);
        }
      }
      
      // submenu
      @if $submenu {
        [data-has-submenu] { 
          @include clearfix(); 
          > label {
            float: right;
          }
        }
        [data-submenu] {
          position: fixed;
          box-sizing: border-box;
          #{$direction}: - $width;
          transition: #{$direction} #{$duration};
          @if $horizontal {
            width: $width;
            height: 100%;
            overflow-x: hidden;
            @if $mode == 'drawer' {
              @include nav-map(top, $map); // set top value
            } @else {
              top: 0;
            }
          } @else if $vertical {
            height: $width;
            left: 0;
            right: 0;
          }
        }
        input:checked ~ [data-submenu] {
          #{$direction}: 0;
        }
      }
    }
  }
  @if $nav-push {
    @if $nav-outside {
      @at-root {
        input:checked ~ #{$nav} {
          margin-#{$direction}: 0;
        }
      }
    } @else {
      input:checked ~ & #{$nav} {
        margin-#{$direction}: 0;
        @if $rotate {
          @include ro-transform(none);
        }
      }
    }
  }

  // page-overlay
  @if $overlay {
    .page-overlay {
      @include ro-transition(unquote("background #{$duration}"));
      position: fixed;
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      #{opposite($direction)}: auto;
      background: transparent;
      z-index: map-get($ro-off-canvas-z-index, 'page-overlay');
      @include ro-transition(all #{$duration});
      input:checked ~ & {
        // background
        background: $overlay-color;
        .lt-ie9 & {
          background: opacify($overlay-color, 1);
          @include opacity(opacity($overlay-color));
        }
        @if $mode == 'drawer' {
          @include nav-map(top, $map); // set top value
        } 
        // push
        #{opposite($direction)}: 0;
        @if $overlay-push {
          #{$direction}: $width;
        } @else {
          #{$direction}: 0;
        }
      }
    }
  }

}

@mixin nav-map($prop, $map) {
  $bps: map-keys($map);

  @each $bp in $bps {
    @if $bp == null or $bp == 'default' {
      #{$prop}: map-get($map, $bp);
    } @else {
      @include bp('max' $bp) {
        #{$prop}: map-get($map, $bp);
      }
    }
  }
}