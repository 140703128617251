// *** parallelogram *** //
@mixin parallelogram($key) {
  $background: args-get-type($key, 'color');
  $angle: args-get-type($key, 'number');
  @if not $background {
    $background: args-get-type($key, 'string');
  }

  position: relative;
  &:before {
    content: ''; 
    position: absolute;
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0; 
    z-index: -1;

    @if $background {
      background-color: $background;
    }
    @if $angle {
      @include ro-transform(skew($angle));
    }
  }
}