// http://thesassway.com/advanced/inverse-trigonometric-functions-with-sass
// pow
@function pow($number, $exp) {
  $value: 1;
  @if $exp > 0 {
    @for $i from 1 through $exp {
      $value: $value * $number;
    }
  }
  @else if $exp < 0 {
    @for $i from 1 through -$exp {
      $value: $value / $number;
    }
  }
  @return $value;
}

// fact
@function fact($number) {
  $value: 1;
  @if $number > 0 {
    @for $i from 1 through $number {
      $value: $value * $i;
    }
  }
  @return $value;
}

// pi
@function pi() {
  @return 3.14159265359;
}

// rad
@function rad($angle) {
  $unit: unit($angle);
  $unitless: $angle / ($angle * 0 + 1);
  // If the angle has 'deg' as unit, convert to radians.
  @if $unit == deg {
    $unitless: $unitless / 180 * pi();
  }
  @return $unitless;
}

// sin
@function sin($angle) {
  $sin: 0;
  $angle: rad($angle);
  // Iterate a bunch of times.
  @for $i from 0 through 10 {
    $sin: $sin + pow(-1, $i) * pow($angle, (2 * $i + 1)) / fact(2 * $i + 1);
  }
  @return $sin;
}

// cos
@function cos($angle) {
  $cos: 0;
  $angle: rad($angle);
  // Iterate a bunch of times.
  @for $i from 0 through 10 {
    $cos: $cos + pow(-1, $i) * pow($angle, 2 * $i) / fact(2 * $i);
  }
  @return $cos;
}

// tan
@function tan($angle) {
  @return sin($angle) / cos($angle);
}

// sqrt
// http://www.antimath.info/css/sass-sqrt-function/
@function sqrt($r) {
  @if $r < 0 {
    @return false;
  } @else if $r == 0 {
    @return 0;
  } @else {
    $x0: 1;
    $x1: $x0;
   
    @for $i from 1 through 10 {
      $x1: $x0 - ($x0 * $x0 - $r) / (2 * $x0);
      $x0: $x1;
    }
   
    @return $x1;
  }
}
