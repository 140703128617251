// reset
// ========

// reset html font family, to prevent a layout issue in some language system (e.g. korean system)
html { font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; }

// set min-width to 320
html { min-width: 320px; overflow-x: hidden; }

// make images flexible
img { max-width: 100%; height: auto; }

// full width image
img.full { width: 100%; display: block; }

// prevent an image issue on IE 8
@media \0screen {
  img { width: auto; }
}

// reset chrome icon-font
[class^="icon-"]::before, 
[class*=" icon-"]::before,
[class^="icon-"]:before, 
[class*=" icon-"]:before { display: none; } 

// reset form styles
button:focus,input:focus,select:focus,textarea:focus {outline: 0; }
label { cursor: pointer; }

// remove margin and padding
figure,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,p { margin: 0; }
dl,ul,ol { padding: 0; }

// reset list styles
ol,ul { list-style:none; }

// reset link style
a { text-decoration: none; color: inherit; }

.hidden-checkbox { position: absolute; left: -10000px; }