@function opposite($direction){
  @if $direction == left { 
    @return right; 
  } @else if $direction == right { 
    @return left; 
  } @else if $direction == top { 
    @return bottom; 
  } @else if $direction == bottom { 
    @return top; 
  } @else { 
    @return false; 
  }
}
