// *** priority-nav *** //
@mixin priority-nav() {
  position: relative;
  > .js-nav-toggle {
    position: absolute;
    right: 0;
    height: 100%;
    cursor: pointer;
  }
  .visible-links {
    display: inline-table;
    > li { 
      display: table-cell; 
      white-space: nowrap;
    }
  }
  .hidden-links {
    position: absolute;
    right: 0;
    top: 100%;
  }
  .is-hidden { visibility: hidden; }
}
