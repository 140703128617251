// *** contrast *** //
@function contrast($key) {
  $light: args-get-next($key, 'light', #fff);
  $dark: args-get-next($key, 'dark', #000);

  @if type-of($key) == 'list' {
    $key: list-remove($key, $light);
    $key: list-remove($key, $dark);
  }
  $color: args-get-type($key, 'color');

  // @error $color;
  @if $color {
    @if brightness($color) < 65% { 
      @return $light; 
    } @else { 
      @return $dark; 
    }
  }
} 

// From John W. Long jlong
// Brightness math based on:
// http://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
$red-magic-number: 241;
$green-magic-number: 691;
$blue-magic-number: 68;
$brightness-divisor: $red-magic-number + $green-magic-number + $blue-magic-number;
 
@function brightness($color) {
  // Extract color components
  $red-component: red($color);
  $green-component: green($color);
  $blue-component: blue($color);
   
  // Calculate a brightness value in 3d color space between 0 and 255
  $number: sqrt((($red-component * $red-component * $red-magic-number) + ($green-component * $green-component * $green-magic-number) + ($blue-component * $blue-component * $blue-magic-number)) / $brightness-divisor);
   
  // Convert to percentage and return
  @return 100% * $number / 255;
}
 
