// search
// =========

.search {
  &-form {
    margin-bottom: 20px;
    :first-child { width: 80%; }
    :last-child { width: 20%; }
  }
  &-heading {
    margin-bottom: 15px;
    border-bottom: 1px solid $color-border;
  }
}