// *** type *** //
@mixin type($key){
  $check: join(join(join($ro-font-weights, $ro-font-styles), $ro-text-aligns), $ro-text-transforms);

  // set up font-size, font-weight, line-height
  $font-size: null;
  $font-weight: null;
  $line-height: null;
  @each $item in $key {
    @if type-of($item) == 'number' {
      @if unitless($item) {
        @if $item >= 100 {
          $font-weight: $item;
        } @else {
          $line-height: $item;
        }
      } @else {
        $font-size: $item;
      }
    }
  }

  // set up font-family, font-weight, font-style, text-align, text-transform
  $font-family: args-get-type($key, 'string', $check);
  $font-weight: args-get($key, $ro-font-weights); 
  $font-style: args-get($key, $ro-font-styles);
  $text-align: args-get($key, $ro-text-aligns);
  $text-transform: args-get($key, $ro-text-transforms);

  // remove prefix
  @if type-of($font-weight) == 'string' {
    $font-weight: string-slice-from($font-weight, '-', after);
  }
  @if type-of($font-style) == 'string' {
    $font-style: string-slice-from($font-style, '-', after);
  }
  @if type-of($text-align) == 'string' {
    $text-align: string-slice-from($text-align, '-', after);
  }
  @if type-of($text-transform) == 'string' {
    $text-transform: string-slice-from($text-transform, '-', after);
  }


  // *** output *** //
  @if $font-size {
    font-size: $font-size;
    @if unit($font-size) == 'px' {
      font-size: rem($font-size);
    }
  }
  @if $line-height {
    line-height: $line-height;
  }
  @if $font-weight {
    font-weight: $font-weight;
  }
  @if $font-style {
    font-style: $font-style;
  }
  @if $text-align {
    text-align: $text-align;
  }
  @if $text-transform {
    text-transform: $text-transform;
  }
  @if $font-family {
    font-family: unquote($font-family);
  }
}
