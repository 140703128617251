// *** slider-gallery *** //
@mixin slider-gallery($key){
  $items: 1;

  // options
  $style: args-get($key, $ro-slider-gallery-style);
  $direction: args-get($key, $ro-direction, left);
  $angle: args-get-next($key, 'angle', 60deg);
  $keep: args-get($key, 'keep');

  // autoplay
  $autoplay: args-get($key, 'autoplay');
  $autoplay-js: args-get($key, 'autoplay-js');
  $progress-bar: args-get($key, 'progress-bar');
  $hoverpause: args-get($key, 'hoverpause');
  $speed: args-get-next($key, 'speed', 1s);
  $timeout: args-get-next($key, 'timeout', 3s);
  $time-per-item: ($speed + $timeout);

  @if type-of($key) == number {
    $items: $key;
  } @else if type-of($key) == list {

    @each $item in $key {
      @if type-of($item) == number and unitless($item) {
        $items: $item;
      }
    }
  }

  $prefix: get-slider-id(&);
  $id: "#" + $prefix;

  // angle
  @if $style == 'rotate-out' {
    $angle: - $angle;
  }

  // transform values 
  $transform-before: '';
  $transform-current: '';
  $transform-after: '';
  $perspective: perspective(1000px);
  @if index(('rotate-in' 'rotate-out'), $style) {
    @if $direction == top {
      $transform-before: translateY(100%) rotateX(- $angle);
      $transform-current: translateY(0) rotateX(0deg);
      $transform-after: translateY(-100%) rotateX($angle);
    } @else if $direction == bottom {
      $transform-before: translateY(-100%) rotateX($angle);
      $transform-current: translateY(0) rotateX(0deg);
      $transform-after: translateY(100%) rotateX(- $angle);
    } @else if $direction == left {
      $transform-before: translateX(100%) rotateY($angle);
      $transform-current: translateX(0) rotateY(0deg);
      $transform-after: translateX(-100%) rotateY(- $angle);
    } @else if $direction == right {
      $transform-before: translateX(-100%) rotateY(- $angle);
      $transform-current: translateX(0) rotateY(0deg);
      $transform-after: translateX(100%) rotateY($angle);
    }
  } @else if $style == 'scale-in' {
    $transform-before: scale(0.8);
    $transform-current: scale(1);
    $transform-after: scale(1.2);
  } @else if $style == 'scale-out' {
    $transform-before: scale(1.2);
    $transform-current: scale(1);
    $transform-after: scale(0.8);
  } @else if $style == 'fall-down' {
    @if index((left top), $direction) {
      $transform-before: scale(0.6) rotate(20deg) translate(80%, 80%);
      $transform-current: scale(1) rotate(0) translate(0, 0);
      $transform-after: scale(0.6) rotate(-20deg) translate(-80%, -80%);
    } @else if index((right bottom), $direction) {
      $transform-before: scale(0.6) rotate(-20deg) translate(-80%, -80%);
      $transform-current: scale(1) rotate(0) translate(0, 0);
      $transform-after: scale(0.6) rotate(20deg) translate(80%, 80%);
    }
  }

  // *** output *** //
  // base
  @if not $keep {
    > input { position: absolute; left: -9999px; }
    @if not $style {
      .outer { overflow: hidden; }
    }
    .inner { 
      position: relative; 
      > li {
        @include item-hide(); 
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
    }
    .controls, .dots, .autoplay { z-index: 2; }
    .controls {
      span { display: none; }
      .prev { float: left; }
      .next { float: right; }
    }
    .dots {
      letter-spacing: -0.34em; // remove white space between inline-block elements
      > label { letter-spacing: 0; } // restore letter-spacing 
      > label, span {
        display: inline-block;
        vertical-align: top;
      }
      .normal { display: block; }
      .active { display: none; }
    }
    .autoplay {
      @if $autoplay { .lt-ie10 & { display: none !important; } }
      .pause {display: none; }
    }
  }

  .outer { transition: height ($speed / 2); }
  .inner > li { transition: opacity $speed, transform $speed; }

  @at-root { @at-root (without: media) {
    #{$id}-autoplay:checked ~ {
      @if $autoplay or $autoplay-js {
        .autoplay {
          .play { display: none; }
          .pause { display: block; }
        }
        @if $autoplay {
          // hide controls and dots
          .controls, .dots {
            display: none; 
            .lt-ie10 & { display: block !important; }
          }
        }
      }
    }

    @for $i from 1 through $items {
      // setting items
      #{$id}-#{$i}:checked ~ {
        .outer li:nth-child(#{$i}) { 
          @include item-show(); 
        }
      }

      // setting controls
      #{$id}-#{$i}:checked ~ {
        @if $i == 1 {
          .controls label:nth-child(#{$items}) .prev, 
          .controls label:nth-child(#{$i + 1}) .next { display: block; }
        } @else if $i == $items {
          .controls label:nth-child(#{$i - 1}) .prev, 
          .controls label:nth-child(1) .next { display: block; }
        } @else {
          .controls label:nth-child(#{$i - 1}) .prev, 
          .controls label:nth-child(#{$i + 1}) .next { display: block; }
        }
      }

      // setting dots
      #{$id}-#{$i}:checked ~ {
      .dots label:nth-child(#{$i}) .active { display: block; }
      .dots label:nth-child(#{$i}) .normal { display: none; }
      }
    }
  }}

  // styles
  @if $style {
    @include ro-keyframes(#{$prefix}-in) {
      0% {
        @include ro-transform($perspective $transform-before);
      }
      100% {
        @include ro-transform($perspective $transform-current);
      }
    }
    @include ro-keyframes(#{$prefix}-out) {
      0% {
        @include ro-transform($perspective $transform-current);
      }
      100% {
        @include ro-transform($perspective $transform-after);
      }
    }
    @for $i from 1 through $items {
      #{$id}-autoplay:not(:checked) ~ #{$id}-#{$i}:checked ~ .outer li {
        &:nth-child(n) { 
          @include ro-animation(#{$prefix}-out $speed); 
          @if index(('rotate-in' 'rotate-out'), $style) {
            @include ro-transform-origin(opposite($direction));
          } @else if $style == 'fall-down' {
            @if index((left top), $direction) {
              @include ro-transform-origin(100% 100%);
            } @else if index((right bottom), $direction) {
              @include ro-transform-origin(100% 0);
            }
          }
        }
        &:nth-child(#{$i}) { 
          @include ro-animation(#{$prefix}-in $speed); 
          @if index(('rotate-in' 'rotate-out'), $style) {
            @include ro-transform-origin($direction);
          } @else if $style == 'fall-down' {
            @if index((left top), $direction) {
              @include ro-transform-origin(100% 0);
            } @else if index((right bottom), $direction) {
              @include ro-transform-origin(100% 100%);
            }
          }
        }
      }
    }
  }
  
  // setting autoplay
  @if $autoplay {
    // animation
    @include ro-keyframes(#{$prefix}) {
      0% { 
        @include item-hide(); 
        @if $style {
          @include ro-transform($perspective $transform-before);
          @if index(('rotate-in' 'rotate-out'), $style) {
            @include ro-transform-origin($direction);
          } @else if $style == 'fall-down' {
            @if index((left top), $direction) {
              @include ro-transform-origin(100% 0);
            } @else if index((right bottom), $direction) {
              @include ro-transform-origin(100% 100%);
            }
          }
        }
      }
      #{percentage($speed / ($time-per-item * $items))} { 
        @include item-show(); 
        @if $style {
          @include ro-transform($perspective $transform-current);
        }
      }
      #{percentage($time-per-item / ($time-per-item * $items))} { 
        @include item-show(); 
        @if $style {
          @include ro-transform($perspective $transform-current);
        }
      }
      #{percentage(($time-per-item + $speed) / ($time-per-item * $items))} { 
        @include item-hide(); 
        @if $style {
          @include ro-transform($perspective $transform-after);
          @if index(('rotate-in' 'rotate-out'), $style) {
            @include ro-transform-origin(opposite($direction));
          } @else if $style == 'fall-down' {
            @if index((left top), $direction) {
              @include ro-transform-origin(100% 100%);
            } @else if index((right bottom), $direction) {
              @include ro-transform-origin(100% 0);
            }
          }
        }
      }
      100% { 
        @include item-hide(); 
      }
    }
    // progress-bar keyframes
    @if $progress-bar {
      @include ro-keyframes(#{$prefix}-progress) {
        0% { width: 0; }
        #{percentage($speed / $time-per-item)} { width: 0; }
        100% { width: 100%; }
      }
    }

    @at-root { @at-root (without: media) {
      #{$id}-autoplay:checked ~ {
        // animation
        .outer li { @include ro-animation( #{$prefix} #{($time-per-item * $items)} ease infinite); }
        @if $progress-bar {
          .autoplay > .autoplay-progress { @include ro-animation( #{$prefix}-progress #{$time-per-item} ease infinite); }
        }

        // delay
        @if $items == 2 {
          .outer li:nth-child(2) { @include ro-animation-delay($time-per-item); }
        } @else {
          @for $i from 1 to $items {
            @if $i == ($items - 1) {
              .outer li:nth-child(#{($i + 1)}) { @include ro-animation-delay(($time-per-item * $i)); }
            } @else {
              .outer li:nth-child(#{($i + 1)}) { @include ro-animation-delay(($time-per-item * $i)); }
            }
          }
        }
      }
    }}
    // pause on hover
    @if $hoverpause {
      &:hover {
        #{$id}-autoplay ~ {
          .outer li { @include ro-animation-play-state(paused); }
          @if $progress-bar {
            .autoplay > .autoplay-progress { @include ro-animation-play-state(paused); }
          }
        }
      }
    }
  }
}
@mixin item-hide() {
  @include opacity(0); 
  position: absolute;
  top: 0;
}
@mixin item-show() {
  @include opacity(1); 
  position: relative;
  z-index: 1;
}

