// sidebar
// ============

.graybox,
.relatedArticle {
  padding: em(10) em(20);
  background-color: #f5f5f5;
}
.page-section {
  margin-bottom: 20px;
}
