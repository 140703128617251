// breadcrumb
// ============

.breadcrumb {
  letter-spacing: -0.34em;
  > a,
  > span {
    @include type(14px bold uppercase);
    display: inline-block;
    margin-right: 0.3em;
    margin-bottom: 10px;
    letter-spacing: 0;
    &:not(:last-child):after {
      content: '/';
      display: inline-block;
      padding: 0 0.4em 0 0.5em;
      color: #888;
      font-weight: normal;
    }
  }
  > a { color: $color-brand; }
  .current,
  > span { color: #888; }
}